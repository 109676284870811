import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import ModalBase from '~/components/modal/ModalBase';
import moment from 'moment';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import TabsBase from '~/components/tabs/TabsBase';
import { TabPanel } from '@mui/lab';
import DescriptionTab from './DescriptionTab';
import InfoTab from './InfoTab';
import { postOrPutDataDanhMuc } from '~/utils/helpers';

const schema = yup.object({
  nha_cung_cap: yup.object().required('Vui lòng chọn người nhân'),
  ngay_ct: yup.date().required('Vui lòng chọn ngày chứng từ'),
  trangThai: yup.object().required('Vui lòng chọn trạng thái')
});

function FormPC({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
}) {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          kho: defaultValues.ma_kho
            ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
            : null,
          pttt: defaultValues.pt_thanh_toan
            ? {
                _id: defaultValues.pt_thanh_toan,
                ten: defaultValues.ten_pt_thanh_toan,
              }
            : null,
            nha_cung_cap: defaultValues.ma_kh
            ? { ma_kh: defaultValues.ma_kh, ten_kh: defaultValues.ten_kh }
            : null,
          trangThai: defaultValues.trang_thai
            ? {
                ma_trang_thai: defaultValues.trang_thai,
                ten_trang_thai: defaultValues.ten_trang_thai,
              }
            : null,
          ngay_ct: defaultValues.ngay_ct ? moment().format('YYYY-MM-DD') : '',
        }
      : {
          ngay_ct: moment().format('YYYY-MM-DD'),
        },
    resolver: yupResolver(schema),
  });

  const { asyncPostData, asyncPutData } = useApisContext();
  const tabRef = useRef();

  const generateDataPost = (values) => {
    const { nha_cung_cap, pttt, kho, trangThai, ...fields } = values;
    const result = {
      ...fields,
      ma_kh: nha_cung_cap?.ma_kh || '',
      ten_kh: nha_cung_cap?.ten_kh || '',
      pt_thanh_toan: pttt?._id || '',
      ten_pt_thanh_toan: pttt?.ten || '',
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
      trang_thai: trangThai?.ma_trang_thai || '',
      ten_trang_thai: trangThai?.ten_trang_thai || '',
      tk_no: '1111',
    };
    return result;
  };

  const handleSave = async (values) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'pc1',
      data: dataPost,
      handleClose,
      reset,
      setLoad,
    });
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      tabRef.current?.handleChange(null, '1');
    }
  }, [errors]);

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="700px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} phiếu chi`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <TabsBase
        tabLabels={[
          { label: 'Thông tin', value: '1' },
          { label: 'Diễn giải', value: '2' },
        ]}
        ref={tabRef}
      >
        <TabPanel value="1" sx={{ padding: '10px 0 0 0' }}>
          <InfoTab
            register={register}
            control={control}
            isEdit={isEdit}
            errors={errors}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '10px 0 0 0' }}>
          <DescriptionTab register={register} />
        </TabPanel>
      </TabsBase>
    </ModalBase>
  );
}

export default FormPC;
