import React, { useEffect } from 'react';
import { SlEnvolope } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import ButtonOption from '~/components/button/ButtonOption';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useSnackbarContext from '~/hooks/hookContext/useSnackbarContext';
import { getNotifications } from '~/redux/actions/notification.action';

function Notification() {
  const alertSnackbar = useSnackbarContext();
  const { callApi } = useApisContext();
  const idApp = useSelector((state) => state.enterprise.idApp);
  const notifications = useSelector((state) => state.notification.data);

  const dispatch = useDispatch();

  useEffect(() => {
    getNotifications({ callApi, alertSnackbar, idApp, dispatch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ButtonOption
      style={{ borderRadius: '4px' }}
      startIcon={<SlEnvolope fontSize="14px" />}
      notification={(notifications || []).length > 0}
    />
  );
}

export default Notification;
