import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterSearch from '../FilterSearch';

function FilterUserGroup({ setCondition }) {
  const [filter, setFilter] = useState({
    tenNhom: '',
  });

  useEffect(() => {
    const condition = {};
    if (filter.tenNhom) {
      condition.group_name = {
        $regex: filter.tenNhom.split(' ').join('.*'),
        $options: 'i',
      };
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Tên nhóm"
        onSearch={(value) => setFilter({ ...filter, tenNhom: value })}
      />
    </Stack>
  );
}

export default FilterUserGroup;
