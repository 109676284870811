import React from 'react';
import ProfileLayout from '~/components/layouts/ProfileLayout';
import { Stack } from '@mui/material';
import TextInput from '~/components/input/TextInput';
import ButtonBase from '~/components/button/ButtonBase';
import useResponsive from '~/hooks/useResponsive';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosPublic } from '~/utils/httpRequest';
import useToken from '~/hooks/useToken';
import useSnackbarContext from '~/hooks/hookContext/useSnackbarContext';

const schema = yup.object({
  oldPassword: yup.string().required('Vui lòng nhập mật khẩu cũ'),
  newPassword: yup
    .string()
    .required('Vui lòng nhập mật khẩu mới')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      'Mật khẩu phải có từ 6 ký tự, ít nhất 1 chữ cái thường và 1 chữ cái in hoa'
    ),
  reNewPassword: yup
    .string()
    .required('Vui lòng xác nhận lại mật khẩu')
    .equals([yup.ref('newPassword')], 'Mật khẩu xác nhận không trùng khớp'),
});

function ChangePasswordPage() {
  const mdMatches = useResponsive({ matchKey: 'up', breakpoint: 'md' });
  const token = useToken();
  const alertSnackbar = useSnackbarContext();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const updatePassword = async (dataPost) => {
    try {
      const resp = await axiosPublic.post(
        `/api/changepassword?access_token=${token}`,
        dataPost
      );
      if (resp && resp?.status === 200) {
        alertSnackbar('success', resp?.data?.message || 'Server error');
        handleClear();
      } else {
        alertSnackbar('error', resp?.response?.data?.message || 'Server error');
      }
    } catch (error) {
      alertSnackbar('error', error?.response?.data?.error || 'Server error');
    }
  };

  const handleClear = () => {
    reset({ oldPassword: '', newPassword: '', reNewPassword: '' });
  };
  const handleUpdate = (values) => {
    updatePassword(values);
  };

  return (
    <ProfileLayout>
      <Stack
        sx={{ padding: mdMatches ? '20px 100px' : '10px 0' }}
        spacing="20px"
      >
        <TextInput
          label="Mật khẩu cũ"
          required
          type="password"
          register={register}
          name="oldPassword"
          errorMessage={errors?.oldPassword?.message}
        />
        <TextInput
          label="Mật khẩu mới"
          required
          type="password"
          register={register}
          name="newPassword"
          errorMessage={errors?.newPassword?.message}
        />
        <TextInput
          label="Nhập lại mật khẩu mới"
          required
          type="password"
          register={register}
          name="reNewPassword"
          errorMessage={errors?.reNewPassword?.message}
        />
        <Stack direction="row" justifyContent="flex-end" spacing="10px">
          <ButtonBase variant="outlined" onClick={handleClear}>
            Nhập lại
          </ButtonBase>
          <ButtonBase
            loading={isSubmitting}
            onClick={handleSubmit(handleUpdate)}
          >
            Xác nhận
          </ButtonBase>
        </Stack>
      </Stack>
    </ProfileLayout>
  );
}

export default ChangePasswordPage;
