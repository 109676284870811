import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import moment from 'moment';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';

const schema = yup.object({
  ten_kh: yup.string().required('Vui lòng nhập tên khách hàng'),
});

function FormCustomer({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
}) {
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          ngay_sinh: moment(defaultValues.ngay_sinh).format('YYYY-MM-DD'),
        }
      : {},
    resolver: yupResolver(schema),
  });

  // handle submit
  const handleSave = async (values) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'customer',
      data: values,
      handleClose,
      reset,
      setLoad,
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="700px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} Khách hàng`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Mã Khách Hàng"
            placeholder="Nhập hoặc tạo tự động"
            name="ma_kh"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Tên Khách Hàng"
            placeholder="VD: Nguyễn Văn A"
            name="ten_kh"
            register={register}
            required
            errorMessage={errors?.ten_kh?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Số điện thoại"
            placeholder="VD: 01694187654"
            name="dien_thoai"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Địa Chỉ"
            placeholder="VD: Quận 9"
            name="dia_chi"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            type="date"
            label="Ngày Sinh"
            name="ngay_sinh"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Email"
            placeholder="VD: ntt@gmail.com"
            name="email"
            register={register}
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default FormCustomer;
