import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import SelectApiInput from '~/components/input/SelectApiInput';
import FormNhanVien from '../../nhanvien/FormNhanVien';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';
import FormPTTT from '../../pttt/FormPTTT';

function KhacTab({ register, control, isEdit, errors }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="nhan_vien"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Nhân viên thực hiện"
              apiCode="dmnv"
              placeholder="Nhân viên thực hiện"
              searchFileds={['ma_nv', 'ten_nv']}
              getOptionLabel={(option) => `${option.ten_nv} (${option.ma_nv})`}
              selectedValue={value}
              value={value || null}
              onSelect={onChange}
              FormAdd={FormNhanVien}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="han_tt"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              label="Hạn thanh toán (số ngày)"
              value={numeralCustom(value).format()}
              onChange={(e) => {
                onChange(numeralCustom(e.target.value).value());
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="pttt"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Hình thức thanh toán"
              apiCode="ptthanhtoan"
              placeholder="Hình thức thanh toán"
              searchFileds={['ten']}
              getOptionLabel={(option) => option.ten}
              selectedValue={value}
              value={value || null}
              onSelect={onChange}
              FormAdd={FormPTTT}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default KhacTab;
