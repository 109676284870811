import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

function IdAppRoute({ children }) {
  const { idApp } = useSelector((state) => state.enterprise);

  if (!idApp) {
    return <Navigate to="/app" />;
  }

  return <>{children}</>;
}

export default IdAppRoute;
