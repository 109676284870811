import numeral from 'numeral';
import moment from 'moment';

numeral.register('locale', 'vi', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'Nghìn',
    million: 'Triệu',
    billion: 'Tỷ',
    trillion: 'Nghìn Tỷ',
  },
  currency: {
    symbol: '₫',
  },
});
numeral.locale('vi');
function formatDateDisplay(date, format = 'DD/MM/YYYY') {
  if (!date) return;
  if (!moment(date).isValid()) return date;
  return moment(date).format(format);
}
async function postOrPutDataDanhMuc({
  isEdit,
  asyncPostData,
  asyncPutData,
  data,
  apiCode,
  handleClose,
  reset,
  setLoad,
}) {
  const method = isEdit ? asyncPutData : asyncPostData;
  const objMethod = { apiCode, data };
  if (isEdit) {
    objMethod.uniqueValue = data._id;
  }
  await method(objMethod).then((resp) => {
    if (!resp.message) {
      handleClose();
      reset();
      setLoad((prev) => prev + 1);
    }
  });
}

const downloadFile = ({ link, alertSnackbar, successMessage }) => {
  if (!link) return;
  try {
    const aTag = document.createElement('a');
    aTag.href = link;
    aTag.download = true;
    aTag.click();
    alertSnackbar('success', successMessage || 'Đã tải xuống file excel mẫu');
  } catch (error) {
    alertSnackbar('error', error?.message || 'Server error');
  }
};

// group by
const groupBy = ({ data = [], callbackMatch }) => {
  if (!callbackMatch) return data;
  let result = {};
  data.forEach((item) => {
    if (result[callbackMatch(item)]) {
      result[callbackMatch(item)].push(item);
    } else {
      result[callbackMatch(item)] = [item];
    }
  });
  return Object.values(result);
};

export {
  numeral as numeralCustom,
  formatDateDisplay,
  postOrPutDataDanhMuc,
  downloadFile,
  groupBy,
};
