import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterSearch from '../FilterSearch';

function FilterEcomsellers({ setCondition }) {
  const [filter, setFilter] = useState({ nha_cung_cap: '', email: '', dien_thoai: '' });

  useEffect(() => {
    const condition = {};
    if (filter.nha_cung_cap) {
      condition.$or = [
        {
          ma_kh: {
            $regex: filter.nha_cung_cap.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_kh: {
            $regex: filter.nha_cung_cap.split(' ').join('.*'),
            $options: 'i',
          },
        },
        { $text: { $search: filter.nha_cung_cap } },
      ];
    }
    if (filter.email) {
      condition.email = { $regex: filter.email, $options: 'i' };
    }
    if (filter.dien_thoai) {
      condition.dien_thoai = { $regex: filter.dien_thoai, $options: 'i' };
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Mã, tên nhà cung cấp"
        onSearch={(value) => setFilter({ nha_cung_cap: value })}
      />
      <FilterSearch
        title="Email"
        onSearch={(value) => setFilter({ email: value })}
      />
      <FilterSearch
        title="Điện thoại"
        onSearch={(value) => setFilter({ dien_thoai: value })}
      />
    </Stack>
  );
}

export default FilterEcomsellers;
