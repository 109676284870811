import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';

function FilterCPMH({ setCondition }) {
  const [filter, setFilter] = useState({
    chi_phi: '',
  });

  useEffect(() => {
    const condition = {
      $or: [
        { ma_cp: { $regex: filter.chi_phi, $options: 'i' } },
        { ten_cp: { $regex: filter.chi_phi, $options: 'i' } },
      ],
    };
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Mã, tên chi phí"
        onSearch={(value) => setFilter({ chi_phi: value })}
      />
    </Stack>
  );
}

export default FilterCPMH;
