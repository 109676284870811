import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';
import FormKho from '../kho/FormKho';

function InfoTab({ register, control, isEdit, errors }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextInput
          type="text"
          label="Số chứng từ"
          placeholder="Nhập hoặc tạo tự động"
          name="so_ct"
          register={register}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="kho"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Kho / chi nhánh"
              required
              apiCode="dmkho"
              placeholder="Kho nhập"
              searchFileds={['ma_kho', 'ten_kho']}
              getOptionLabel={(option) => option.ten_kho}
              selectedValue={value}
              value={value || { ma_kho: '', ten_kho: '' }}
              onSelect={onChange}
              FormAdd={FormKho}
              errorMessage={errors?.kho?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          type="date"
          label="Ngày chứng từ"
          placeholder="Ngày chứng từ"
          name="ngay_ct"
          register={register}
          errorMessage={errors?.ngay_ct?.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="t_tien_nhap_nt"
          render={({ field: { value, onChange } }) => (
            <TextInput
              type="text"
              label="Tổng tiền nhập"
              placeholder="Tổng tiền nhập"
              value={numeralCustom(value).format()}
              onChange={(e) => onChange(numeralCustom(e.target.value).value())}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="nha_cung_cap"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Nhà cung cấp"
              apiCode="ecomsellers"
              placeholder="Đơn vị nhập hàng"
              searchFileds={['ma_kh', 'ten_kh']}
              getOptionLabel={(option) => `${option.ten_kh} - ${option.ma_kh}`}
              selectedValue={value}
              value={value || null}
              onSelect={onChange}
              FormAdd={FormKho}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="trang_thai"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Trạng thái"
              required
              apiCode="trangthai"
              placeholder="Trạng thái phiếu"
              searchFileds={['ten_trang_thai']}
              getOptionLabel={(option) => option.ten_trang_thai}
              selectedValue={value}
              value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
              onSelect={onChange}
              withIdApp={false}
              condition={{ ma_ct: 'PNK', status: true }}
              errorMessage={errors?.trang_thai?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default InfoTab;
