import React, { useState } from 'react';
import {
  Autocomplete,
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

const AutoCompleteStyled = styled(Autocomplete)`
  width: 100%;
  height: 42px;
  border-radius: 6px;
  background-color: #fff;
  & .MuiInputBase-root {
    height: 42px;
    padding: 0 !important;
    & input {
      font-size: 13px;
      padding: 0;
    }
    & input::placeholder {
      font-size: 13px;
      color: #000;
    }
  }
`;

function SelectInput({
  label,
  required = false,
  multiple,
  disabled,
  isOptionEqualToValue = () => true,
  options = [],
  value,
  selectedValue,
  getOptionLabel = () => {},
  onSelect = () => {},
  filterOptions = (option) => option,
  renderOption = () => {},
  sx = {},
  placeholder = '',
  errorMessage,
  autocompleteProps = {},
  ...props
}) {
  const [load, setLoad] = useState(0);

  return (
    <Stack
      spacing="5px"
      sx={{ width: '100%' }}
      alignItems="flex-start"
      {...props}
    >
      {label && (
        <InputLabel sx={{ fontSize: '13px', cursor: 'pointer' }}>
          {label}{' '}
          {required && (
            <Typography
              component="span"
              sx={{ color: 'error.main', fontSize: '12px' }}
            >
              *
            </Typography>
          )}
        </InputLabel>
      )}
      <AutoCompleteStyled
        key={load}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        value={value}
        onChange={(e, newValue) => onSelect(newValue)}
        ListboxProps={{
          className: 'custome-scrolly',
          sx: {
            '& .MuiAutocomplete-option': { fontSize: '12px' },
          },
        }}
        noOptionsText={
          <Stack spacing="10px" alignItems="center">
            <Typography
              sx={{
                fontSize: '13px',
                fontStyle: 'italic',
                textAlign: 'center',
              }}
            >
              Không tìm thấy kết quả
            </Typography>
          </Stack>
        }
        sx={sx}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            sx={{ '& .MuiInputBase-root': { paddingRight: '5px' } }}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off',
              endAdornment:
                !!selectedValue && !disabled ? (
                  <IconButton
                    onClick={() => {
                      setLoad(load + 1);
                      onSelect(null);
                    }}
                  >
                    <MdClose fontSize="14px" />
                  </IconButton>
                ) : null,
            }}
          />
        )}
        {...autocompleteProps}
      />
      {errorMessage && (
        <FormHelperText
          error
          sx={{
            fontSize: '12px',
            fontStyle: 'italic',
            color: 'error.main',
            lineHeight: '13px',
            paddingLeft: '5px',
          }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </Stack>
  );
}

export default SelectInput;
