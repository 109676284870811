async function swDev() {
  let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register(swUrl, {
        scope: '/',
      });
      console.log('Service worker registration succeeded');
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
}

export default swDev;
