import React, { createContext } from 'react';
import { useSelector } from 'react-redux';
import useSnackbarContext from '~/hooks/hookContext/useSnackbarContext';
import useAxiosPrivate from '~/hooks/useAxiosPrivate';
import { axiosPublic } from '~/utils/httpRequest';
export const ApisContext = createContext();

function ApisProvider({ children }) {
  const alertSnackbar = useSnackbarContext();
  const axiosPrivate = useAxiosPrivate();
  const { idApp } = useSelector((state) => state.enterprise);

  // async get list
  const asyncGetList = async ({
    apiCode,
    condition = {},
    withIdApp = true,
  }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate.post(
        `/search${withIdApp ? `/${idApp}` : ''}/${apiCode}`,
        condition
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        alertSnackbar('error', resp?.response?.data?.message || 'Server error');
        return resp.response.data;
      }
    } catch (error) {
      alertSnackbar('error', error?.response?.data?.message || 'Server error');
    }
  };
  // async get list deleted
  const asyncGetListDeleted = async (
    ma_danh_muc,
    condition = {},
    version = 'v1'
  ) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate.post(
        `/${version}/danhmuc/${ma_danh_muc}/search/deleted`,
        condition
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        alertSnackbar('error', resp?.response?.data?.message || 'Server error');
        return resp.response.data;
      }
    } catch (error) {
      alertSnackbar('error', error?.response?.data?.message || 'Server error');
    }
  };
  // async post data
  const asyncPostData = async ({
    method = 'post',
    apiCode,
    data,
    endpoint,
    options,
  }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate[method](
        `/${idApp}/${apiCode}${endpoint || ''}`,
        data,
        options
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        alertSnackbar('error', resp?.response?.data?.message || 'Server error');
        return resp.response.data;
      }
    } catch (error) {
      alertSnackbar('error', error?.message || 'Server error');
    }
  };
  // async put data
  const asyncPutData = async ({ apiCode, uniqueValue, data }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate.put(
        `/${idApp}/${apiCode}/${uniqueValue}`,
        data
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        alertSnackbar('error', resp?.response?.data?.message || 'Server error');
        return resp.response.data;
      }
    } catch (error) {
      alertSnackbar('error', error?.message || 'Server error');
    }
  };
  // async delete
  const asyncDelete = async ({ apiCode, uniqueValue }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate.delete(
        `/${idApp}/${apiCode}/${uniqueValue}`
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        alertSnackbar('error', resp?.response?.data?.message || 'Server error');
        return resp.response.data;
      }
    } catch (error) {
      alertSnackbar('error', error?.message || 'Server error');
    }
  };
  const asyncGetReport = async ({ apiCode, queryObject = {}, data = {} }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      if (!apiCode) return;
      let queryString = '';
      for (let key in queryObject) {
        queryString += `&${key}=${queryObject[key]}`;
      }
      const resp = await axiosPrivate.get(
        `/${idApp}/${apiCode}?rpt=1${queryString}`,
        data
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        alertSnackbar('error', resp?.response?.data?.error || 'Server error');
        return resp.response.data;
      }
    } catch (error) {
      alertSnackbar('error', error?.message || 'Server error');
    }
  };
  const uploadFile = async ({ formData, folder, token }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPublic.post(
        `/api/uploadfile?json=1&folder=${folder}&access_token=${token}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        alertSnackbar('error', resp?.response?.data?.message || 'Server error');
        return resp?.response?.data;
      }
    } catch (error) {
      alertSnackbar('error', error?.message || 'Server errors');
    }
  };
  const callApi = async ({ method = 'post', endpoint, data = {} }) => {
    try {
      if (!navigator.onLine) {
        return;
      }
      const resp = await axiosPrivate[method](endpoint, data);
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        alertSnackbar('error', resp?.response?.data?.message || 'Server error');
        return resp?.response?.data;
      }
    } catch (error) {
      alertSnackbar('error', error?.message || 'Server error');
    }
  };

  return (
    <ApisContext.Provider
      value={{
        asyncGetList,
        asyncGetListDeleted,
        asyncPostData,
        asyncPutData,
        asyncDelete,
        asyncGetReport,
        uploadFile,
        callApi,
      }}
    >
      {children}
    </ApisContext.Provider>
  );
}

export default ApisProvider;
