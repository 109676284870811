import React, { useState, useRef } from 'react';
import ModalBase from '../../modal/ModalBase';
import ButtonBase from '../../button/ButtonBase';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import moment from 'moment';
import TabsBase from '~/components/tabs/TabsBase';
import { TabPanel } from '@mui/lab';
import InfoTab from './InfoTab';
import DetailTab from './DetailTab';
import DescriptionTab from './DescriptionTab';
import { useEffect } from 'react';

const schema = yup.object({
  kho: yup
    .object()
    .typeError('Vui lòng chọn kho')
    .required('Vui lòng chọn kho'),
});

export default function FormPKK({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
}) {
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          kho: {
            ma_kho: defaultValues.ma_kho,
            ten_kho: defaultValues.ten_kho,
          },
          ngay_ct: moment(defaultValues.ngay_ct).format('YYYY-MM-DD'),
        }
      : {
          ngay_ct: moment().format('YYYY-MM-DD'),
        },

    resolver: yupResolver(schema),
  });

  const [details, setDetails] = useState(defaultValues?.details || []);

  const tabRef = useRef();

  const generateDataPost = (values) => {
    const { vatTu, kho, lo, ...fields } = values;
    const result = {
      ...fields,
      ma_kho: kho.ma_kho,
      ten_kho: kho.ten_kho,
    };
    result.details = details
    return result;
  };

  const handleSave = async (values) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'pkk',
      data: dataPost,
      handleClose,
      reset,
      setLoad,
    });
  };

  useEffect(() => {
    if(Object.keys(errors).length > 0) {
      if (Object.keys(errors).length > 0) {
        tabRef.current?.handleChange(null, '1');
      }
    }
  }, [errors])

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} phiếu kiểm kho`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <TabsBase
        tabLabels={[
          { label: 'Thông tin', value: '1' },
          { label: 'Chi tiết', value: '2' },
          { label: 'Diễn giải', value: '3' },
        ]}
        ref={tabRef}
      >
        <TabPanel value="1" sx={{ padding: '10px 0 0 0' }}>
          <InfoTab
            control={control}
            errors={errors}
            isEdit={isEdit}
            register={register}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '10px 0 0 0' }}>
          <DetailTab
            isEditMaster={isEdit}
            details={details}
            setDetails={setDetails}
          />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: '10px 0 0 0' }}>
         <DescriptionTab register={register} />
        </TabPanel>
      </TabsBase>
    </ModalBase>
  );
}
