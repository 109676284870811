import {
  loginStart,
  loginFail,
  loginSuccess,
  logoutSuccess,
  editProfile,
} from '../reducrers/auth.reducer';
import { axiosPublic } from '~/utils/httpRequest';
import { Buffer } from 'buffer';
import { PUBLIC_URL } from '~/utils/constants';

// login user
const loginUser = async ({
  username,
  password,
  dispatch,
  navigate,
  alertSnackbar,
}) => {
  try {
    dispatch(loginStart());
    const credentials = Buffer.from(username + ':' + password).toString(
      'base64'
    );
    const basicAuth = 'Basic ' + credentials;
    const resp = await axiosPublic.get(`${PUBLIC_URL}/auth/local`, {
      headers: {
        Authorization: basicAuth,
      },
    });
    if (resp && resp.status === 200) {
      dispatch(loginSuccess(resp.data));
      navigate('/');
    }
  } catch (error) {
    dispatch(loginFail());
    alertSnackbar('error', error?.response?.data?.message || 'Server error');
    return error;
  }
};
// logout user
const logoutUser = (dispatch, setThemeId) => {
  dispatch(logoutSuccess());
  localStorage.clear();
  setThemeId(0);
};

// get profile
const getProfile = async ({ dispatch, token, alertSnackbar }) => {
  try {
    const resp = await axiosPublic.get(`/api/profile?access_token=${token}`);
    if (resp && resp.status === 200) {
      dispatch(editProfile(resp.data));
    }
  } catch (error) {
    alertSnackbar('error', error?.message || 'Server error');
  }
};
const updateProfile = async ({ data, dispatch, token, alertSnackbar }) => {
  try {
    const resp = await axiosPublic.post(
      `/api/updateprofile?access_token=${token}`,
      data
    );
    if (resp && resp.status === 200) {
      alertSnackbar('success', resp.data);
      getProfile({ dispatch, token, alertSnackbar });
    }
  } catch (error) {
    alertSnackbar('error', error?.message || 'Server error');
  }
};

export { loginUser, logoutUser, getProfile, updateProfile };
