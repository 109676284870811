import { useSelector } from 'react-redux';

const useToken = () => {
  const loginData = useSelector((state) => state.auth.login);
  if (!loginData?.data) {
    return null;
  }
  return loginData.data.token
};
export default useToken;
