import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterSelectApi from '../FilterSelectApi';
import FormKho from '~/components/form/kho/FormKho';

function FilterLo({ setCondition }) {
  const [filter, setFilter] = useState({
    lo: '',
    kho: null,
  });

  useEffect(() => {
    const condition = {
      $or: [
        { ma_lo: { $regex: filter.lo.split(' ').join('.*'), $options: 'i' } },
        { ten_lo: { $regex: filter.lo.split(' ').join('.*'), $options: 'i' } },
        { $text: { $search: filter.lo } },
      ],
    };
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Mã, tên lô"
        onSearch={(value) => setFilter({ lo: value })}
      />
      <FilterSelectApi
        apiCode="dmkho"
        searchFileds={['ma_kho', 'ten_kho']}
        title="Kho / chi nhánh"
        getOptionLabel={(option) => option.ten_kho}
        value={filter.kho || null}
        onSelect={(value) => setFilter({ ...filter, kho: value })}
        FormAdd={FormKho}
      />
    </Stack>
  );
}

export default FilterLo;
