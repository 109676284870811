import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSnackbarContext from '~/hooks/hookContext/useSnackbarContext';
import useToken from '~/hooks/useToken';
import { getProfile } from '~/redux/actions/auth.action';

export default function PrivateRoute({ children }) {
  const token = useToken();
  const dataLogin = useSelector((state) => state.auth.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alertSnackbar = useSnackbarContext();

  useEffect(() => {
    if (!dataLogin?.data?.token) {
      navigate('/login');
    } else {
      if (navigator.onLine) {
        getProfile({ dispatch, token, alertSnackbar });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLogin?.data?.token]);

  return <>{children}</>;
}
