import { updateNotifications } from '../reducrers/notifycation.reducer';

export const getNotifications = async ({
  alertSnackbar,
  callApi,
  idApp,
  dispatch,
}) => {
  try {
    const resp = await callApi({
      method: 'get',
      endpoint: `/notification?q={"exfields.id_app":"${idApp}"}`,
    });
    if (resp) {
      dispatch(updateNotifications(resp));
    }
  } catch (error) {
    alertSnackbar?.('error', error?.message || 'Server error');
  }
};
