import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterTimeFromTo from '../FilterTimeFromTo';
import moment from 'moment';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import FilterSelectApi from '../FilterSelectApi';

function FilterBaoCaoSoQuy({
  setQueryObject = () => {},
  setConcern = () => {},
}) {
  const [timeOption, setTimeOption] = useState(null);
  const [filter, setFilter] = useState({
    timeFrom: moment().startOf('months').format('YYYY-MM-DD'),
    timeTo: moment().format('YYYY-MM-DD'),
    kho: null,
  });

  useEffect(() => {
    const queryObject = {
      tk: 111,
    };
    const concern = {
      api: 'soquy',
      convertData: (data) => {
        data.splice(-2, 0, data.shift());
        data.shift();
        return data;
      },
      columns: [
        {
          name: 'Số CT',
          selector: (row) => row.so_ct,
          sortable: true,
          width: '100px',
          wrap: true,
        },
        {
          name: 'Loại chứng từ',
          selector: (row) => row.ma_ct,
          sortable: true,
          wrap: true,
          width: '130px',
          center: true,
        },
        {
          name: 'Diễn giải',
          selector: (row) => row.dien_giai,
          sortable: true,
          left: true,
          wrap: true,
          maxWidth: '180px',
        },
        {
          name: 'Giá trị',
          selector: (row) => row.ps_no_nt,
          format: (row) => numeralCustom(row.ps_no_nt).format(),
          sortable: true,
          center: true,
        },
        {
          name: 'Mã kho',
          selector: (row) => row.ma_kho,
          sortable: true,
          wrap: true,
          center: true,
        },
        {
          name: 'Ngày chứng từ',
          selector: (row) => row.ngay_ct,
          format: (row) => formatDateDisplay(row.ngay_ct),
          sortable: true,
          right: true,
        },
      ],
    };
    if (filter.timeFrom) {
      queryObject.tu_ngay = filter.timeFrom;
    }
    if (filter.timeTo) {
      queryObject.den_ngay = filter.timeTo;
    }
    if (filter.kho) {
      queryObject.ma_kho = filter.kho.ma_kho;
    }

    setConcern(concern);
    setQueryObject(queryObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOption, filter]);

  return (
    <Stack spacing={1}>
      <FilterSelectApi
        title="Kho"
        apiCode="dmkho"
        value={
          filter.kho
            ? { ma_kho: filter.kho.ma_kho, ten_kho: filter.kho.ten_kho }
            : null
        }
        searchFileds={['ma_kho', 'ten_kho']}
        getOptionLabel={(option) => option.ten_kho}
        onSelect={(value) => setFilter({ ...filter, kho: value })}
      />
      <FilterTimeFromTo
        defaultTimeFrom={filter.timeFrom}
        defaultTimeTo={filter.timeTo}
        title="Thời gian"
        showOptions
        onSearch={(time) => setFilter({ ...filter, ...time })}
        onOptionChange={setTimeOption}
      />
    </Stack>
  );
}

export default FilterBaoCaoSoQuy;
