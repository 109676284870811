import React, {memo} from 'react';
import { Box, Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';
import ButtonOption from '~/components/button/ButtonOption';
import { useLocation, useNavigate } from 'react-router-dom';
import { menus } from '~/utils/menu';

function MenuBar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <MenuBarWrapper bgColor={theme.palette.primary.second}>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <MenuBarContainer>
          {menus.map(menu => {
            let isActive = false
            if(menu.subs) {
              isActive = menu.subs.some(sub => {
                return location.pathname === sub.path
              })
            }else if(menu.path) {
              isActive = location.pathname === menu.path
            }
            let subItems = undefined
            if(menu.subs?.length > 0) {
              subItems = menu.subs.map(item => {
                return {
                  text: item.text,
                  startIcon: item.icon,
                  primary: true,
                  onClick: () => navigate(item.path),
                  active: location.pathname === item.path,
                }
              })
            }
            return(
             <ButtonOption
             key={menu.text}
             active={isActive}
             style={{ borderRadius: '4px' }}
             primary
             startIcon={menu.icon}
             menuColor={theme.palette.primary.second}
             onClick={menu.path ? () => navigate(menu.path) : undefined}
             popupOptions={subItems}
           >
             {menu.text}
           </ButtonOption>
          ) })}
        </MenuBarContainer>
      </Container>
    </MenuBarWrapper>
  );
}

export default memo(MenuBar);

const MenuBarWrapper = styled.div`
  height: 42px;
  background-color: ${(props) => props.bgColor};
`;
const MenuBarContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  gap: 10px;
`;
