import React, { useEffect, useState } from 'react';
import ModalBase from '../../modal/ModalBase';
import ButtonBase from '../../button/ButtonBase';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import { dsDanhMuc } from '~/utils/data';
import CheckboxInput from '~/components/input/CheckboxInput';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';

const schemaBase = {
  email: yup.string().required('Vui lòng nhập trường này'),
  name: yup.string().required('Vui lòng nhập họ tên'),
};
export default function FormParticipant({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
}) {
  const { asyncPostData, asyncPutData } = useApisContext();
  const [schema, setSchema] = useState(yup.object(schemaBase));
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          userGroup: defaultValues.group_id
            ? {
                _id: defaultValues.group_id,
                group_name: defaultValues.group_name,
              }
            : null,
          auto_create_user: false,
        }
      : null,
    resolver: yupResolver(schema),
  });
  const [readOnlyPassword, setReadOnlyPassword] = useState(true);

  const autoCreateUser = watch('auto_create_user');

  const generateDataPost = (values) => {
    const { userGroup, ...data } = values;
    const result = { ...data };
    if (userGroup) {
      result.group_id = userGroup._id;
      result.group_name = userGroup.group_name;
    }
    return result;
  };

  const handleSave = async (values) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'participant',
      data: dataPost,
      handleClose,
      reset,
      setLoad,
    });
  };

  useEffect(() => {
    if (autoCreateUser === true) {
      setSchema(
        yup.object({
          ...schemaBase,
          password: yup.string().required('Vui lòng nhập mật khẩu'),
        })
      );
    } else {
      setSchema(yup.object(schemaBase));
    }
  }, [autoCreateUser]);

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="700px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} người dùng`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Email hoặc số điện thoại"
            placeholder="email@gmail.com"
            name="email"
            register={register}
            required
            errorMessage={errors?.email?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Họ tên"
            placeholder="Họ tên"
            name="name"
            required
            register={register}
            errorMessage={errors?.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="userGroup"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                label="Nhóm"
                apiCode="usergroup"
                placeholder="Nhóm người dùng"
                searchFileds={['group_name']}
                getOptionLabel={(option) => option.group_name}
                selectedValue={value}
                value={value || { _id: '', group_name: '' }}
                onSelect={onChange}
                FormAdd={dsDanhMuc['usergroup'].Form}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="auto_create_user"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Tạo người dùng nếu người dùng chưa được tạo"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        {!!autoCreateUser && (
          <Grid item xs={12}>
            <TextInput
              label="Mật khẩu"
              placeholder="Mật khẩu"
              name="password"
              type="password"
              required
              register={register}
              errorMessage={errors?.password?.message}
              textFieldProps={{
                InputProps: { readOnly: readOnlyPassword },
                onFocus: () => setReadOnlyPassword(false),
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="admin"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Là người quản trị"
                name="admin"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Còn hoạt động"
                name="status"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}
