import React from 'react';
import { Box } from '@mui/material';
import ButtonOption from '~/components/button/ButtonOption';
import { AiOutlineSetting } from 'react-icons/ai';
import MenuSettings from './MenuSettings';
import { FaUserCircle } from 'react-icons/fa';
import { TbLogout } from 'react-icons/tb';
import { logoutUser } from '~/redux/actions/auth.action';
import { useDispatch, useSelector } from 'react-redux';
import Themes from './Themes';
import { useGlobalTheme } from '~/context/themeContext';
import { useNavigate } from 'react-router-dom';
import { BsBuildingCheck } from 'react-icons/bs';
import Notification from './Notification';
import MenuStore from './MenuStore';

function HeaderActions({ isAdmin }) {
  const authData = useSelector((state) => state.auth);
  const [, setThemeId] = useGlobalTheme();
  const { data } = useSelector((state) => state.enterprise);
  const dispath = useDispatch();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <MenuStore isAdmin={isAdmin} />
      <Themes />
      <ButtonOption
        style={{ borderRadius: '4px' }}
        endIcon={<AiOutlineSetting fontSize="14px" />}
        PopupComponent={<MenuSettings />}
        popupOptions={[
          {
            text: 'Nhóm người dùng và phân quyền',
            onClick: () => navigate('/list/usergroup'),
          },
          {
            text: 'Danh sách tất cả người dùng',
            onClick: () => navigate('/list/participant'),
          },
          // {
          //   text: 'Cấu hình công ty',
          //   onClick: () => navigate('/'),
          // },
        ]}
      >
        Thiết lập
      </ButtonOption>
      <Notification />
      <ButtonOption
        style={{ borderRadius: '4px' }}
        endIcon={<FaUserCircle fontSize="14px" />}
        menuWidth="200px"
        popupOptions={[
          {
            text: 'Tài khoản',
            startIcon: <FaUserCircle fontSize="14px" />,
            onClick: () => navigate('/profile'),
          },
          {
            text: 'Danh sách công ty',
            startIcon: <BsBuildingCheck fontSize="14px" />,
            onClick: () => navigate('/app'),
          },
          {
            text: 'Đăng xuất',
            startIcon: <TbLogout fontSize="14px" />,
            onClick: () => logoutUser(dispath, setThemeId),
          },
        ]}
      >
        {!!data ? `${data.name} -` : ''}{' '}
        {authData?.profile?.email || 'email@gmail.com'}
      </ButtonOption>
    </Box>
  );
}

export default HeaderActions;
