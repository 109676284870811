import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterSelectApi from '../FilterSelectApi';
import FilterTimeFromTo from '../FilterTimeFromTo';
import moment from 'moment';

function FilterPBL({ setCondition }) {
  const [filter, setFilter] = useState({
    so_ct: '',
    kho: null,
    hanghoa: null,
    kenhban: null,
    khachhang: null,
    nhanvien: null,
    pttt: null,
    trangthai: null,
    timeFrom: '',
    timeTo: moment().format('YYYY-MM-DD'),
  });

  useEffect(() => {
    const condition = {};
    if (filter.so_ct) {
      condition.so_ct = filter.so_ct;
    }
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    if (filter.hanghoa) {
      condition.details = {
        $elemMatch: {
          ma_vt: filter.hanghoa.ma_vt,
        },
      };
    }
    if (filter.kenhban) {
      condition.kenh_ban = filter.kenhban.ma_kenh;
    }
    if (filter.khachhang) {
      condition.ma_kh = filter.khachhang.ma_kh;
    }
    if (filter.nhanvien) {
      condition.ma_nv = filter.nhanvien.ma_nv;
    }
    if (filter.pttt) {
      condition.pt_thanh_toan = filter.pttt._id;
    }
    if (filter.trangthai) {
      condition.trang_thai = filter.trangthai.ma_trang_thai;
    }
    if (filter.timeFrom || filter.timeTo) {
      const startDate = moment(filter.timeFrom).toDate();
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      const endDate = moment(filter.timeTo).toDate();
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      if (filter.timeFrom && filter.timeTo) {
        condition.ngay_ct = { $gte: startDate, $lte: endDate };
      } else if (filter.timeFrom) {
        condition.ngay_ct = { $gte: startDate };
      } else if (filter.timeTo) {
        condition.ngay_ct = { $lte: endDate };
      }
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Số chứng từ"
        onSearch={(value) => setFilter({ ...filter, so_ct: value })}
      />
      <FilterSelectApi
        title="Trạng thái"
        apiCode="trangthai"
        condition={{ ma_ct: 'PBL', status: true }}
        withIdApp={false}
        value={
          filter.trangthai
            ? {
                ma_trang_thai: filter.trangthai.ma_trang_thai,
                ten_trang_thai: filter.trangthai.ten_trang_thai,
              }
            : null
        }
        searchFileds={['ma_trang_thai', 'ten_trang_thai']}
        getOptionLabel={(option) => option.ten_trang_thai}
        onSelect={(value) => setFilter({ ...filter, trangthai: value })}
      />
      <FilterSelectApi
        title="Kho"
        apiCode="dmkho"
        value={
          filter.kho
            ? { ma_kho: filter.kho.ma_kho, ten_kho: filter.kho.ten_kho }
            : null
        }
        searchFileds={['ma_kho', 'ten_kho']}
        getOptionLabel={(option) => option.ten_kho}
        onSelect={(value) => setFilter({ ...filter, kho: value })}
      />
      <FilterSelectApi
        title="Hàng Hóa"
        apiCode="dmvt"
        value={
          filter.hanghoa
            ? { ma_vt: filter.hanghoa.ma_vt, ten_vt: filter.hanghoa.ten_vt }
            : null
        }
        searchFileds={['ma_vt', 'ten_vt']}
        getOptionLabel={(option) => option.ten_vt}
        onSelect={(value) => setFilter({ ...filter, hanghoa: value })}
      />
      <FilterSelectApi
        title="Khách hàng"
        apiCode="customer"
        value={
          filter.khachhang
            ? {
                ma_kh: filter.khachhang.ma_kh,
                ten_kh: filter.khachhang.ten_kh,
              }
            : null
        }
        searchFileds={['ma_kh', 'ten_kh']}
        getOptionLabel={(option) => `${option.ten_kh} - ${option.ma_kh}`}
        onSelect={(value) => setFilter({ ...filter, khachhang: value })}
      />
      <FilterSelectApi
        title="Nhân viên"
        apiCode="dmnv"
        value={
          filter.nhanvien
            ? {
                ma_nv: filter.nhanvien.ma_nv,
                ten_nv: filter.nhanvien.ten_nv,
              }
            : null
        }
        searchFileds={['ma_nv', 'ten_nv']}
        getOptionLabel={(option) => `${option.ten_nv} - ${option.ma_nv}`}
        onSelect={(value) => setFilter({ ...filter, nhanvien: value })}
      />
      <FilterSelectApi
        title="Kênh bán"
        apiCode="dmkenhbanhang"
        value={
          filter.kenhban
            ? {
                ma_kenh: filter.kenhban.ma_kenh,
                ten_kenh: filter.kenhban.ten_kenh,
              }
            : null
        }
        searchFileds={['ma_kenh', 'ten_kenh']}
        getOptionLabel={(option) => option.ten_kenh}
        onSelect={(value) => setFilter({ ...filter, kenhban: value })}
      />
      <FilterSelectApi
        title="Phương thức thanh toán"
        apiCode="ptthanhtoan"
        value={
          filter.pttt
            ? {
                _id: filter.pttt._id,
                ten: filter.pttt.ten,
              }
            : null
        }
        searchFileds={['ten']}
        getOptionLabel={(option) => option.ten}
        onSelect={(value) => setFilter({ ...filter, pttt: value })}
      />
      <FilterTimeFromTo
        title="Ngày chứng từ"
        defaultTimeTo={filter.timeTo}
        onSearch={(time) =>
          setFilter({
            ...filter,
            ...time,
          })
        }
      />
    </Stack>
  );
}

export default FilterPBL;
