import React, { useState } from 'react';
import { Stack } from '@mui/material';
import ButtonBase from '~/components/button/ButtonBase';
import { BsPlusCircle } from 'react-icons/bs';
import TableDisplay from '~/components/table/TableDisplay';
import FormAddChiPhi from './FormAddChiPhi';
import { cloneDeep } from 'lodash';
import { numeralCustom } from '~/utils/helpers';

const columns = [
  {
    name: 'Tên chi phí',
    selector: (row) => row.ten_cp,
    left: true,
    wrap: true,
    minWidth: '150px',
  },
  {
    name: 'Tiền chi phí',
    selector: (row) => row.tien_cp_nt,
    format: (row) => numeralCustom(row.tien_cp_nt).format(),
    center: true,
    minWidth: '120px',
  },
  {
    name: 'Kiểu phân bổ',
    selector: (row) => row.kieu_pb,
    right: true,
  },
];

function ChiPhiTab({
  chiphis = [],
  setChiphis = () => {},
  isEditMaster = false,
}) {
  const [openForm, setOpenForm] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);

  const handleOpenForm = () => {
    setOpenForm(true);
  };
  const handleCloseForm = () => {
    setOpenForm(false);
    setDefaultValue(null);
  };
  const handleAddChiphi = (data) => {
    const chiphisClone = cloneDeep(chiphis);
    const index = chiphisClone.findIndex((item) => item.ma_cp === data.ma_cp);
    if (index >= 0) {
      chiphisClone.splice(index, 1, data);
    } else {
      chiphisClone.push(data);
    }
    setChiphis(chiphisClone);
  };
  const handleDeleteChiphi = (row) => {
    const chiphisMoi = chiphis.filter((item) => item.ma_cp !== row.ma_cp);
    setChiphis(chiphisMoi);
  };
  const handleRowClicked = (row) => {
    setDefaultValue(row);
    handleOpenForm();
  };

  return (
    <>
      {openForm && (
        <FormAddChiPhi
          open={openForm}
          handleClose={handleCloseForm}
          addChiphi={handleAddChiphi}
          defaultValue={defaultValue}
        />
      )}
      <Stack sx={{ width: '100%' }} spacing="10px">
        <Stack
          direction="row"
          spacing="5px"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ButtonBase
            variant="outlined"
            startIcon={<BsPlusCircle style={{ fontSize: '14px' }} />}
            onClick={handleOpenForm}
          >
            Thêm dòng
          </ButtonBase>
        </Stack>
        <TableDisplay
          data={chiphis}
          uniqueKey="ma_cp"
          columns={columns}
          onRowClicked={handleRowClicked}
          handleDelete={handleDeleteChiphi}
        />
      </Stack>
    </>
  );
}

export default ChiPhiTab;
