import React, { memo } from 'react';
import ModalBase from './ModalBase';
import ButtonBase from '../button/ButtonBase';
import TableDisplay from '../table/TableDisplay';
import { IconButton } from '@mui/material';
import { BsDownload } from 'react-icons/bs';
import useSnackbarContext from '~/hooks/hookContext/useSnackbarContext';
import { useMemo } from 'react';
import { downloadFile } from '~/utils/helpers';
import useLinkImage from '~/hooks/useLinkImage';

function ModalExcelFiles({ open, handleClose = () => {}, data = [] }) {
  const alertSnackbar = useSnackbarContext();
  const generateLink = useLinkImage();

  const columns = useMemo(() => {
    return [
      {
        name: 'Tên file',
        selector: (row) => row.title,
        grow: 1,
      },
      {
        name: 'Tải về',
        selector: (row) => (
          <IconButton
            onClick={() =>
              downloadFile({ link: generateLink(row.file), alertSnackbar })
            }
          >
            <BsDownload size={14} />
          </IconButton>
        ),
        grow: 1,
        right: true,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalBase
      width="600px"
      title="Tải về file excel"
      open={open}
      handleClose={handleClose}
      sx={{ zIndex: '9999999' }}
      actions={[
        <ButtonBase key={1} variant="outlined" onClick={handleClose}>
          Đóng
        </ButtonBase>,
      ]}
    >
      <TableDisplay columns={columns} data={data} />
    </ModalBase>
  );
}

export default memo(ModalExcelFiles);
