import React from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { Grid } from '@mui/material';
import TextInput from '~/components/input/TextInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import ButtonBase from '~/components/button/ButtonBase';
import ModalBase from '~/components/modal/ModalBase';
import moment from 'moment';
import CheckboxInput from '~/components/input/CheckboxInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import FormKho from '../kho/FormKho';
import FormProduct from '../product/FormProduct';

const schema = yup.object({
  ma_lo: yup.string().required('Vui lòng nhập mã lô'),
  ten_lo: yup.string().required('Vui lòng nhập tên lô'),
  han_sd: yup
    .date()
    .typeError('Vui lòng nhập hạn sử dụng')
    .required('Vui lòng nhập hạn sử dụng'),
  kho: yup
    .object()
    .typeError('Vui lòng chọn kho')
    .required('Vui lòng chọn kho'),
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
});

export default function FormLo({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
}) {
  
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          kho: defaultValues.ma_kho
            ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
            : { ma_kho: '', ten_kho: '' },
          vat_tu: defaultValues.ma_vt
            ? { ma_vt: defaultValues.ma_vt, ten_vt: defaultValues.ten_vt }
            : { ma_vt: '', ten_vt: '' },
          ngay_san_xuat: defaultValues.ngay_san_xuat
            ? moment(defaultValues.ngay_san_xuat).format('YYYY-MM-DD')
            : null,
          han_sd: moment(defaultValues.han_sd).format('YYYY-MM-DD'),
        }
      : {},
    resolver: yupResolver(schema),
  });

  const generateDataPost = (values) => {
    const { kho, vat_tu, ...data } = values;
    return {
      ...data,
      ma_kho: kho.ma_kho,
      ten_kho: kho.ten_kho,
      ma_vt: vat_tu.ma_vt,
      ten_vt: vat_tu.ten_vt,
    };
  };

  const handleSave = async (values) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmlo',
      data: dataPost,
      handleClose,
      reset,
      setLoad,
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="700px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} lô`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            disabled={isEdit}
            label="Mã lô"
            placeholder="VD: Lo0001"
            name="ma_lo"
            register={register}
            required
            errorMessage={errors?.ma_lo?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Tên lô"
            placeholder="Tên của lô hàng"
            name="ten_lo"
            required
            register={register}
            errorMessage={errors?.ten_lo?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="kho"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                label="Kho"
                required
                apiCode="dmkho"
                placeholder="Kho theo lô"
                searchFileds={['ma_kho', 'ten_kho']}
                getOptionLabel={(option) => option.ten_kho}
                selectedValue={value}
                value={value || { ma_kho: '', ten_kho: '' }}
                onSelect={onChange}
                FormAdd={FormKho}
                errorMessage={errors?.kho?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="vat_tu"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                label="Hàng hóa"
                required
                apiCode="dmvt"
                placeholder="Hàng hóa theo lô"
                searchFileds={['ma_vt', 'ten_vt']}
                getOptionLabel={(option) => option.ten_vt}
                selectedValue={value}
                value={value || { ma_vt: '', ten_vt: '' }}
                onSelect={onChange}
                FormAdd={FormProduct}
                errorMessage={errors?.vat_tu?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Ngày sản xuất"
            type="date"
            name="ngay_san_xuat"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Hạn sử dụng"
            type="date"
            placeholder="Hạn sử dụng"
            name="han_sd"
            required
            register={register}
            errorMessage={errors?.han_sd?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Còn sử dụng"
                name="status"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}
