import FilterReportBanhang from '~/components/filter/baocaobanhang/FilterReportBanhang';
import FilterBaoCaoHangHoa from '~/components/filter/baocaohanghoa/FilterBaoCaoHangHoa';
import FilterBaoCaoSoQuy from '~/components/filter/baocaosoquy/FilterBaoCaoSoQuy';

const reports = {
  hanghoa: {
    title: 'Báo cáo hàng hóa',
    Filter: FilterBaoCaoHangHoa,
  },
  banhang: {
    title: 'Báo cáo bán hàng',
    Filter: FilterReportBanhang,
  },
  soquy: {
    title: 'Báo cáo sổ quỹ',
    Filter: FilterBaoCaoSoQuy,
  },
};
export default reports;
