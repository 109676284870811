import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';

function FilterXuatXu({ setCondition }) {
  const [filter, setFilter] = useState({
    xuat_xu: '',
  });

  useEffect(() => {
    const condition = {};
    if (filter.xuat_xu) {
      condition.$or = [
        {
          ma_xuat_xu: {
            $regex: filter.xuat_xu.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_xuat_xu: {
            $regex: filter.xuat_xu.split(' ').join('.*'),
            $options: 'i',
          },
        },
      ];
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Mã, tên xuất xứ"
        onSearch={(value) => setFilter({ xuat_xu: value })}
      />
    </Stack>
  );
}

export default FilterXuatXu;
