import React, { useState, useEffect, lazy, Suspense, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import { Skeleton } from '@mui/material';
import ButtonBase from '~/components/button/ButtonBase';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import TabsBase from '~/components/tabs/TabsBase';
import { FiSave } from 'react-icons/fi';
import { TabPanel } from '@mui/lab';
import InfoTab from './InfoTab';
import DinhMucTonTab from './DinhMucTonTab';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useToken from '~/hooks/useToken';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
const DescriptionTab = lazy(() => import('./DescriptionTab'));
const ImageTab = lazy(() => import('./ImageTab'));
const LoTab = lazy(() => import('./LoTab'));
const KhoTab = lazy(() => import('./KhoTab'));

const schema = yup.object({
  ten_vt: yup.string().required('Vui lòng nhập tên hàng hóa'),
  don_vi_tinh: yup
    .object()
    .shape({
      ma_dvt: yup.string().required(),
      ten_dvt: yup.string().required(),
    })
    .nullable()
    .required('Vui lòng chọn đơn vị tính'),
});

function FormProduct({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
}) {
  const { uploadFile, asyncPostData, asyncPutData } = useApisContext();
  const token = useToken();
  const [thumbnails, setThumbnails] = useState({
    picture: null,
    picture2: null,
    picture3: null,
  });
  const [dvts, setDvts] = useState(defaultValues?.ds_dvt || []);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          nhom_vat_tu: defaultValues.ma_nvt
            ? { _id: defaultValues.ma_nvt, ten_nvt: defaultValues.ten_nvt }
            : null,
          don_vi_tinh: defaultValues.ma_dvt
            ? { ma_dvt: defaultValues.ma_dvt, ten_dvt: defaultValues.ten_dvt }
            : null,
          xuat_xu: defaultValues.ma_xuat_xu
            ? {
                ma_xuat_xu: defaultValues.ma_xuat_xu,
                ten_xuat_xu: defaultValues.ten_xuat_xu,
              }
            : null,
        }
      : {
          ma_vt: '',
          ten_vt: '',
          barcode: '',
          ten_tat: '',
          nhom_vat_tu: null,
          don_vi_tinh: null,
          gia_ban_le: 0,
          xuat_xu: '',
          theo_doi_lo: false,
        },
    resolver: yupResolver(schema),
  });
  const tabRef = useRef();

  const convertDataToPost = async (values) => {
    const { nhom_vat_tu, don_vi_tinh, xuat_xu, ...fields } = values;
    const result = { ...fields };
    result.ma_nvt = nhom_vat_tu?._id || '';
    result.ten_nvt = nhom_vat_tu?.ten_nvt || '';
    result.ma_dvt = don_vi_tinh?.ma_dvt || '';
    result.ten_dvt = don_vi_tinh?.ten_dvt || '';
    result.ma_xuat_xu = xuat_xu?.ma_xuat_xu || '';
    result.ten_xuat_xu = xuat_xu?.ten_xuat_xu || '';
    result.ds_dvt = dvts;

    // save picture
    const entrieImages = Object.entries(thumbnails);
    for (let i = 0; i < entrieImages.length; i++) {
      const entry = entrieImages[i];
      if (!!entry[1]) {
        const formData = new FormData();
        formData.append('file', entry[1]);
        const resp = await uploadFile({ formData, folder: 'products', token });
        if (resp) {
          result[entry[0]] = resp.fileUrl;
        }
      } else if (entry[1] === undefined) {
        result[entry[0]] = '';
      }
    }

    return result;
  };

  // handle save
  const handleSave = async (values) => {
    const dataPost = await convertDataToPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmvt',
      data: dataPost,
      handleClose,
      reset,
      setLoad,
    });
  };
  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      tabRef.current.handleChange(null, '1');
    }
  }, [errors]);

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} hàng hóa`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <TabsBase
        tabLabels={[
          { label: 'Thông tin', value: '1' },
          { label: 'Định mức tồn', value: '2' },
          { label: 'Mô tả', value: '3' },
          { label: 'Hình ảnh', value: '4' },
          {
            label: isEdit && defaultValues?.theo_doi_lo ? 'Lô hàng' : '',
            value: '5',
          },
          { label: defaultValues?.ma_vt ? 'Thẻ kho' : '', value: '6' },
        ]}
        ref={tabRef}
      >
        <TabPanel value="1" sx={{ padding: '10px 0 0 0' }}>
          <InfoTab
            control={control}
            register={register}
            errors={errors}
            isEdit={isEdit} 
            dvts={dvts}
            setDvts={setDvts}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: '10px 0 0 0' }}>
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height="40px" />}
          >
            <DinhMucTonTab register={register} />
          </Suspense>
        </TabPanel>
        <TabPanel value="3" sx={{ padding: '10px 0 0 0' }}>
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height="40px" />}
          >
            <DescriptionTab register={register} />
          </Suspense>
        </TabPanel>
        <TabPanel value="4" sx={{ padding: '10px 0 0 0' }}>
          <Suspense
              fallback={<Skeleton variant="rounded" width="100%" height="40px" />}
            >
            <ImageTab
              setThumbnails={setThumbnails}
              defaultValues={defaultValues}
            />
          </Suspense>
        </TabPanel>
        <TabPanel value="5" sx={{ padding: '10px 0 0 0' }}>
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height="40px" />}
          >
            {isEdit && defaultValues?.theo_doi_lo && (
              <LoTab maVt={defaultValues?.ma_vt} />
            )}
          </Suspense>
        </TabPanel>
        {defaultValues?.ma_vt && (
          <TabPanel value="6" sx={{ padding: '10px 0 0 0' }}>
            <Suspense
              fallback={
                <Skeleton variant="rounded" width="100%" height="40px" />
              }
            >
              <KhoTab
                maVt={defaultValues?.ma_vt}
                theoDoiLo={defaultValues?.theo_doi_lo}
              />
            </Suspense>
          </TabPanel>
        )}
      </TabsBase>
    </ModalBase>
  );
}

export default FormProduct;
