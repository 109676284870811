import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterSelectApi from '../FilterSelectApi';
import FormNVT from '~/components/form/productGroup/FormNVT';

function FilterProductGroup({ setCondition }) {
  const [filter, setFilter] = useState({ tenNvt: '', nhomMe: null });

  useEffect(() => {
    const condition = {};
    if (filter.tenNvt) {
      condition.ten_nvt = {
        $regex: filter.tenNvt.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if (filter.nhomMe) {
      condition.nh_me = filter.nhomMe._id;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Mã, tên nhóm vật tư"
        onSearch={(value) => setFilter({ tenNvt: value })}
      />
      <FilterSelectApi
        title="Nhóm mẹ"
        apiCode="dmnvt"
        searchFileds={['ten_nvt']}
        value={filter.nhomMe}
        onSelect={(value) => setFilter({ ...filter, nhomMe: value })}
        FormAdd={FormNVT}
      />
    </Stack>
  );
}

export default FilterProductGroup;
