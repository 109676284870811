import React, { useState } from 'react';
import ButtonBase from '../button/ButtonBase';
import ModalBase from './ModalBase';
import { Stack } from '@mui/material';
import TextInput from '../input/TextInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { axiosPublic } from '~/utils/httpRequest';
import useToken from '~/hooks/useToken';
import useSnackbarContext from '~/hooks/hookContext/useSnackbarContext';
import { v4 } from 'uuid';

const schema = yup.object({
  newPassword: yup
    .string()
    .required('Vui lòng nhập mật khẩu mới')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
      'Mật khẩu phải có từ 6 ký tự, ít nhất 1 số, 1 chữ cái thường và 1 chữ cái in hoa'
    ),
  reNewPassword: yup
    .string()
    .required('Vui lòng xác nhận lại mật khẩu')
    .equals([yup.ref('newPassword')], 'Mật khẩu xác nhận không trùng khớp'),
});

function ModalUpdatePassword({ email }) {
  const token = useToken();
  const alertSnackbar = useSnackbarContext();
  const [open, setOpen] = useState(false);
  const [readOnlyPassword, setReadOnlyPassword] = useState({
    newPassword: true,
    reNewPassword: true,
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { email },
  });

  const handleClear = () => {
    reset({ oldPassword: '', newPassword: '', reNewPassword: '' });
  };

  const updatePassword = async (dataPost) => {
    try {
      const resp = await axiosPublic.post(
        `/api/changepasswordByAdmin?access_token=${token}`,
        dataPost
      );
      if (resp && resp?.status === 200) {
        alertSnackbar('success', resp?.data?.message || 'Server error');
        handleClear();
      } else {
        alertSnackbar('error', resp?.response?.data?.message || 'Server error');
      }
    } catch (error) {
      alertSnackbar('error', error?.response?.data?.error || 'Server error');
    }
  };

  const handleUpdate = (values) => {
    updatePassword(values);
  };

  return (
    <>
      <ModalBase
        open={open}
        handleClose={() => setOpen(false)}
        title="Khôi phục mật khẩu"
        width="500px"
        actions={[
          <ButtonBase
            key={v4()}
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Hủy
          </ButtonBase>,
          <ButtonBase
            key={v4()}
            variant="contained"
            onClick={handleSubmit(handleUpdate)}
            loading={isSubmitting}
          >
            Xác nhận
          </ButtonBase>,
        ]}
      >
        <Stack spacing="10px">
          <TextInput
            label="Email"
            required
            type="text"
            register={register}
            name="email"
            textFieldProps={{
              InputProps: { readOnly: true },
            }}
          />
          <TextInput
            label="Mật khẩu mới"
            required
            type="password"
            register={register}
            name="newPassword"
            placeholder="Nhập mật khẩu mới"
            errorMessage={errors?.newPassword?.message}
            textFieldProps={{
              InputProps: { readOnly: readOnlyPassword.newPassword },
              onFocus: () =>
                setReadOnlyPassword({
                  ...readOnlyPassword,
                  newPassword: false,
                }),
            }}
          />
          <TextInput
            label="Nhập lại mật khẩu mới"
            required
            type="password"
            register={register}
            name="reNewPassword"
            placeholder="Nhập lại mật khẩu mới"
            errorMessage={errors?.reNewPassword?.message}
            textFieldProps={{
              InputProps: { readOnly: readOnlyPassword.reNewPassword },
              onFocus: () =>
                setReadOnlyPassword({
                  ...readOnlyPassword,
                  reNewPassword: false,
                }),
            }}
          />
        </Stack>
      </ModalBase>
      <ButtonBase variant="outlined" onClick={() => setOpen(true)}>
        Khôi phục mật khẩu
      </ButtonBase>
    </>
  );
}

export default ModalUpdatePassword;
