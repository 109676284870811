import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';

function FilterCustomer({ setCondition }) {
  const [filter, setFilter] = useState({
    khachHang: '',
    dien_thoai: '',
    email: '',
  });

  useEffect(() => {
    const condition = { isseller: { $ne: true } };
    if (filter.khachHang) {
      condition.$or = [
        {
          ma_kh: {
            $regex: filter.khachHang.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_kh: {
            $regex: filter.khachHang.split(' ').join('.*'),
            $options: 'i',
          },
        },
        { $text: { $search: filter.khachHang } },
      ];
    }
    if (filter.dien_thoai) {
      condition.dien_thoai = {
        $regex: filter.dien_thoai,
        $options: 'i',
      };
    }
    if (filter.email) {
      condition.email = {
        $regex: filter.email,
        $options: 'i',
      };
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Mã, tên khách hàng"
        onSearch={(value) => setFilter({ ...filter, khachHang: value })}
      />
      <FilterSearch
        title="Số điện thoại"
        onSearch={(value) => setFilter({ ...filter, dien_thoai: value })}
      />
      <FilterSearch
        title="Email"
        onSearch={(value) => setFilter({ ...filter, email: value })}
      />
    </Stack>
  );
}

export default FilterCustomer;
