import React from 'react';
import { Grid } from '@mui/material';
import { Controller} from 'react-hook-form';
import TextInput from '~/components/input/TextInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import { numeralCustom } from '~/utils/helpers';
import FormCustomer from '../customer/FormCustomer';
import FormKho from '../kho/FormKho';
import FormPTTT from '../pttt/FormPTTT';


function InfoTab({ register, control, errors, isEdit }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextInput
          label="Số chứng từ"
          placeholder="Nhập hoặc tạo tự động"
          name="so_ct"
          register={register}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="khachHang"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Người nộp"
              required
              apiCode="customer"
              placeholder="Người nộp"
              searchFileds={['ma_kh', 'ten_kh']}
              getOptionLabel={(option) => `${option.ten_kh} - ${option.ma_kh}`}
              selectedValue={value}
              value={value || null}
              onSelect={onChange}
              FormAdd={FormCustomer}
              errorMessage={errors?.khachHang?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          type="date"
          label="Ngày chứng từ"
          name="ngay_ct"
          register={register}
          required
          errorMessage={errors?.ngay_ct?.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="kho"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Kho"
              apiCode="dmkho"
              placeholder="Kho"
              searchFileds={['ma_kho', 'ten_kho']}
              getOptionLabel={(option) => option.ten_kho}
              selectedValue={value}
              value={value || { ma_kho: '', ten_kho: '' }}
              onSelect={onChange}
              FormAdd={FormKho}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="t_tt_nt"
          render={({ field: { value, onChange } }) => (
            <TextInput
              value={numeralCustom(value).format()}
              onChange={(e) => {
                const val = numeralCustom(e.target.value).value();
                onChange(val);
              }}
              label="Thành tiền"
              placeholder="Thành tiền"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="pttt"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Phương thức thanh toán"
              apiCode="ptthanhtoan"
              placeholder="Tiền mặt"
              searchFileds={['ten']}
              getOptionLabel={(option) => option.ten}
              selectedValue={value}
              value={value || { ten: '' }}
              onSelect={onChange}
              FormAdd={FormPTTT}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="trangThai"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Trạng thái"
              required
              apiCode="trangthai"
              placeholder="Trạng thái"
              searchFileds={['ten_trang_thai']}
              getOptionLabel={(option) => option.ten_trang_thai}
              selectedValue={value}
              value={value || null}
              onSelect={onChange}
              withIdApp={false}
              condition={{ ma_ct: 'PT1', status: true }}
              errorMessage={errors?.trangThai?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

  


export default InfoTab;
