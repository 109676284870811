import FormProduct from '~/components/form/product/FormProduct';
import { formatDateDisplay, numeralCustom } from './helpers';
import FormNVT from '~/components/form/productGroup/FormNVT';
import FormDVT from '~/components/form/dvt/FormDVT';
import FormKho from '~/components/form/kho/FormKho';
import FilterProduct from '~/components/filter/product/FilterProduct';
import FilterProductGroup from '~/components/filter/productGroup/FilterProductGroup';
import FilterDVT from '~/components/filter/donViTinh/FilterDVT';
import FilterKho from '~/components/filter/kho/FilterKho';
import FormEcomSellers from '~/components/form/ecomsellers/FormEcomsellers';
import FormPNK from '~/components/form/pnk/FormPNK';
import FormLo from '~/components/form/lo/FormLo';
import FilterLo from '~/components/filter/lo/FilterLo';
import FilterPNK from '~/components/filter/pnk/FilterPNK';
import FormPKK from '~/components/form/pkk/FormPKK';
import FilterPKK from '~/components/filter/pkk/FilterPKK';
import FormPXDC from '~/components/form/pdn/FormPDN';
import FilterPDN from '~/components/filter/pdn/FilterPDN';
import FilterNghiepVu from '~/components/filter/nghiepvu/FilterNghiepVu';
import FormKB from '~/components/form/kb/FormKB';
import FilterKB from '~/components/filter/kenhBan/FilterKB';
import FormPTTT from '~/components/form/pttt/FormPTTT';
import FilterPTTT from '~/components/filter/pttt/FilterPTTT';
import FormCustomer from '~/components/form/customer/FormCustomer';
import FilterCustomer from '~/components/filter/customer/FilterCustomer';
import FormPT from '~/components/form/phieuthu/FormPT';
import FilterPT from '~/components/filter/phieuThu/FilterPT';

import FormPBL from '~/components/form/pbl/FormPBL';
import FormPC from '~/components/form/phieuchi/FormPC';
import FilterPC from '~/components/filter/phieuChi/FilterPC';
import FilterPBL from '~/components/filter/pbl/FilterPBL';
import FormNhanVien from '~/components/form/nhanvien/FormNhanVien';
import FilterNhanVien from '~/components/filter/nhanvien/FilterNhanVien';
import ButtonBase from '~/components/button/ButtonBase';
import FilterUserGroup from '~/components/filter/usergroup/FilterUserGroup';
import FormUserGroup from '~/components/form/usergroup/FormUserGroup';
import FilterParticipant from '~/components/filter/participant/FilterParticipant';
import FormParticipant from '~/components/form/participant/FormParticipant';
import ModalUpdatePassword from '~/components/modal/ModalUpdatePassword';
import FormNghiepVu from '~/components/form/nghiepvu/FormNghiepVu';
import FormXuatXu from '~/components/form/xuatxu/FormXuatXu';
import FilterXuatXu from '~/components/filter/xuatxu/FilterXuatXu';
import FilterEcomsellers from '~/components/filter/ecomsellers/FilterEcomsellers';
import FormPN1 from '~/components/form/pn1/FormPN1';
import { Chip } from '@mui/material';
import FilterPN1 from '~/components/filter/pn1/FilterPN1';
import FormCPMH from '~/components/form/dmcpmh/FormCPMH';
import FilterCPMH from '~/components/filter/dmcpmh/FilterCPMH';
import FilterRPT from '~/components/filter/rpt/FilterRPT';
import FormRPT from '~/components/form/rpt/FormRPT';

const dsDanhMuc = {
  dmvt: {
    title: 'hàng hóa',
    uniqueKey: 'ma_vt',
    Form: FormProduct,
    Filter: FilterProduct,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_vt,
        width: '150px',
        sortable: true,
        wrap: true,
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_vt,
        minWidth: '200px',
        sortable: true,
      },
      {
        name: 'Mã vạch',
        selector: (row) => row.ma_vt2,
        width: '150px',
        center: true,
        sortable: true,
      },
      {
        name: 'Nhóm hàng hóa',
        selector: (row) => row.ten_nvt,
        width: '150px',
        center: true,
        sortable: true,
      },
      {
        name: 'Giá bán lẻ',
        selector: (row) => row.gia_ban_le,
        center: true,
        sortable: true,
        width: '120px',
        format: (row) => numeralCustom(row.gia_ban_le).format(),
      },
      {
        name: 'Đơn vị tính',
        selector: (row) => row.ten_dvt,
        width: '120px',
        center: true,
        sortable: true,
      },
    ],
  },
  dmnvt: {
    title: 'nhóm hàng hóa',
    Filter: FilterProductGroup,
    Form: FormNVT,
    columns: [
      {
        name: 'Tên',
        selector: (row) => row.ten_nvt,
        sortable: true,
      },
      {
        name: 'Nhóm mẹ',
        selector: (row) => row.ten_nh_me,
        sortable: true,
      },
    ],
  },
  dmdvt: {
    title: 'đơn vị tính',
    uniqueKey: 'ma_dvt',
    Filter: FilterDVT,
    Form: FormDVT,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_dvt,
        sortable: true,
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_dvt,
        sortable: true,
      },
    ],
  },
  dmkho: {
    title: 'kho',
    uniqueKey: 'ma_kho',
    Form: FormKho,
    Filter: FilterKho,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_kho,
        sortable: true,
        width: '120px',
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_kho,
        sortable: true,
        width: '200px',
        wrap: true,
      },

      {
        name: 'Địa chỉ',
        selector: (row) => row.dia_chi,
        sortable: true,
        width: '200x',
        wrap: true,
      },
      {
        name: 'Điện thoại',
        selector: (row) => row.exfields?.dien_thoai,
        sortable: true,
        minWidth: '150px',
        center: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
        minWidth: '200px',
        center: true,
        right: true,
      },
    ],
  },
  ecomsellers: {
    title: 'nhà cung cấp',
    Form: FormEcomSellers,
    Filter: FilterEcomsellers,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_kh,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_kh,
        sortable: true,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
        left: true,
        minWidth: '220px',
      },
      {
        name: 'Điện thoại',
        selector: (row) => row.dien_thoai,
        sortable: true,
        right: true,
        minWidth: '120px',
      },
    ],
  },
  dmlo: {
    title: 'lô',
    uniqueKey: 'ma_lo',
    Form: FormLo,
    Filter: FilterLo,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_lo,
        sortable: true,
        width: '100px',
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_lo,
        sortable: true,
        minWidth: '200px',
        wrap: true,
      },
      {
        name: 'Kho',
        selector: (row) => row.ten_kho,
        sortable: true,
        minWidth: '200px',
        wrap: true,
        center: true,
      },
      {
        name: 'Tên hàng',
        selector: (row) => row.ten_vt,
        sortable: true,
        minWidth: '200px',
        center: true,
        wrap: true,
      },
      {
        name: 'Ngày sản xuất',
        selector: (row) => row.ngay_san_xuat,
        format: (row) => formatDateDisplay(row.ngay_san_xuat),
        sortable: true,
        minWidth: '150px',
        center: true,
      },
      {
        name: 'Hạn sử dụng',
        selector: (row) => row.han_sd,
        format: (row) => formatDateDisplay(row.han_sd),
        sortable: true,
        minWidth: '150px',
        right: true,
      },
    ],
  },
  pnk: {
    title: 'phiếu nhập kho',
    Form: FormPNK,
    Filter: FilterPNK,
    columns: [
      {
        name: 'Số ct',
        selector: (row) => row.so_ct,
        sortable: true,
        width: '120px',
        center: true,
        wrap: true,
      },
      {
        name: 'Trạng thái',
        selector: (row) => row.ten_trang_thai,
        cell: (row) => {
          return (
            <Chip
              label={row.ten_trang_thai}
              size="small"
              sx={{
                backgroundColor: row.color,
                color: '#fff',
                '& .MuiChip-label': { fontSize: '12px' },
              }}
            />
          );
        },
        sortable: true,
        minWidth: '140px',
        wrap: true,
      },
      {
        name: 'Kho',
        selector: (row) => row.ten_kho,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        sortable: true,
        center: true,
        format: (row) => formatDateDisplay(row.ngay_ct),
        minWidth: '150px',
      },
      {
        name: 'Nhà cung cấp',
        selector: (row) => row.ten_kh,
        sortable: true,
        minWidth: '130px',
        center: true,
        wrap: true,
      },
      {
        name: 'Tổng tiền nhập',
        selector: (row) => row.t_tien_nhap_nt,
        sortable: true,
        right: true,
        minWidth: '140px',
        format: (row) => numeralCustom(row.t_tien_nhap_nt).format(),
      },
    ],
  },
  pkk: {
    title: 'phiếu kiểm kho',
    Form: FormPKK,
    Filter: FilterPKK,
    columns: [
      {
        name: 'Số chứng từ',
        selector: (row) => row.so_ct,
        sortable: true,
        minWidth: '50px',
        left: true,
      },
      {
        name: 'Kho',
        selector: (row) => row.ten_kho,
        sortable: true,
        center: true,
        minWidth: '150px',
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        sortable: true,
        center: true,
        format: (row) => formatDateDisplay(row.ngay_ct),
        minWidth: '150px',
      },
      {
        name: 'Tổng chênh lêch',
        selector: (row) => row.t_sl,
        sortable: true,
        right: true,
        minWidth: '150px',
      },
    ],
  },
  pdn: {
    title: 'phiếu xuất điều chuyển',
    Form: FormPXDC,
    Filter: FilterPDN,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        sortable: true,
        width: '100px',
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct),
        sortable: true,
        minWidth: '150px',
        left: true,
      },
      {
        name: 'Từ kho',
        selector: (row) => row.ten_kho_x,
        sortable: true,
        minWidth: '150px',
        center: true,
      },
      {
        name: 'Đến kho',
        selector: (row) => row.ten_kho_n,
        sortable: true,
        minWidth: '150px',
        right: true,
      },
    ],
  },
  dmkenhbanhang: {
    title: 'Kênh bán',
    uniqueKey: 'ma_kenh',
    Form: FormKB,
    Filter: FilterKB,
    columns: [
      {
        name: 'Mã Kênh',
        selector: (row) => row.ma_kenh,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: 'Tên Kênh',
        selector: (row) => row.ten_kenh,
        sortable: true,
        minWidth: '100px',
      },
    ],
  },
  ptthanhtoan: {
    title: 'phương thức thanh toán',
    Form: FormPTTT,
    Filter: FilterPTTT,
    columns: [
      {
        name: 'Tên phương thức thanh toán',
        selector: (row) => row.ten,
        sortable: true,
        minWidth: '100px',
      },
    ],
  },
  customer: {
    title: 'khách hàng',
    uniqueKey: 'sdt',
    Form: FormCustomer,
    Filter: FilterCustomer,
    columns: [
      {
        name: 'Mã khách hàng',
        selector: (row) => row.ma_kh,
        sortable: true,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Tên khách hàng',
        selector: (row) => row.ten_kh,
        sortable: true,
        minWidth: '140px',
      },
      {
        name: 'Số điện thoại',
        selector: (row) => row.dien_thoai,
        sortable: true,
        minWidth: '140px',
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
        minWidth: '200px',
        wrap: true,
      },
      {
        name: 'Địa chỉ',
        selector: (row) => row.dia_chi,
        sortable: true,
        minWidth: '200px',
        wrap: true,
        right: true,
      },
    ],
  },
  pt1: {
    title: 'phiếu thu',
    Form: FormPT,
    Filter: FilterPT,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        sortable: true,
        left: true,
        width: '100px',
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct),
        sortable: true,
        center: true,
        minWidth: '100px',
      },
      {
        name: 'Kho',
        selector: (row) => row.ten_kho,
        sortable: true,
        center: true,
        minWidth: '100px',
      },
      {
        name: 'Người nộp',
        selector: (row) => row.ten_kh,
        sortable: true,
        center: true,
        minWidth: '100px',
      },
      {
        name: 'Tổng tiền',
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
        sortable: true,
        right: true,
        minWidth: '100px',
      },
    ],
  },
  pc1: {
    title: 'phiếu chi',
    Form: FormPC,
    Filter: FilterPC,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        sortable: true,
        left: true,
        width: '100px',
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct),
        sortable: true,
        center: true,
        minWidth: '100px',
      },
      {
        name: 'Kho',
        selector: (row) => row.ten_kho,
        sortable: true,
        center: true,
        minWidth: '100px',
      },
      {
        name: 'Người nhận',
        selector: (row) => row.ten_kh,
        sortable: true,
        center: true,
        minWidth: '100px',
      },
      {
        name: 'Tổng tiền',
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
        sortable: true,
        right: true,
        minWidth: '100px',
      },
    ],
  },
  pbl: {
    title: 'phiếu bán lẻ',
    Form: FormPBL,
    Filter: FilterPBL,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: 'Kho',
        selector: (row) => row.ten_kho,
        sortable: true,
        minWidth: '120px',
        wrap: true,
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct),
        sortable: true,
        width: '140px',
      },
      {
        name: 'Trạng thái',
        selector: (row) => row.ten_trang_thai,
        cell: (row) => {
          return (
            <Chip
              label={row.ten_trang_thai}
              size="small"
              sx={{
                backgroundColor: row.color,
                color: '#fff',
                '& .MuiChip-label': { fontSize: '12px' },
              }}
            />
          );
        },
        sortable: true,
        minWidth: '140px',
        wrap: true,
        center: true,
      },
      {
        name: 'Tổng thành tiền',
        selector: (row) => row.t_tt,
        format: (row) => numeralCustom(row.t_tt).format(),
        sortable: true,
        center: true,
        width: '140px',
      },
      {
        name: 'Nhân viên',
        selector: (row) => row.ten_nv,
        sortable: true,
        width: '120px',
        wrap: true,
        center: true,
      },
      {
        name: 'Khách hàng',
        selector: (row) => row.ten_kh,
        sortable: true,
        center: true,
        wrap: true,
        width: '120px',
      },
      {
        name: 'Kênh bán',
        selector: (row) => row.ten_kenh,
        sortable: true,
        width: '120px',
        center: true,
        wrap: true,
      },
      {
        name: 'Phương thức thanh toán',
        selector: (row) => row.ten_pt_thanh_toan,
        sortable: true,
        width: '180px',
        right: true,
      },
    ],
  },
  dmnv: {
    title: 'nhân viên',
    uniqueKey: 'ma_nv',
    Form: FormNhanVien,
    Filter: FilterNhanVien,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_nv,
        sortable: true,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_nv,
        sortable: true,
        minWidth: '100px',
        left: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        sortable: true,
        minWidth: '140px',
        left: true,
        wrap: true,
      },
      {
        name: 'Điện thoại',
        selector: (row) => row.dien_thoai,
        sortable: true,
        minWidth: '100px',
        center: true,
      },
      {
        name: 'Ngày sinh',
        selector: (row) => row.ngay_sinh,
        format: (row) => formatDateDisplay(row.ngay_sinh),
        sortable: true,
        minWidth: '100px',
        right: true,
      },
    ],
  },
  usergroup: {
    title: 'nhóm người dùng',
    uniqueKey: '_id',
    Filter: FilterUserGroup,
    Form: FormUserGroup,
    columns: [
      {
        name: 'Tên nhóm',
        selector: (row) => row.group_name,
        sortable: true,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Ngày tạo',
        selector: (row) => row.date_created,
        format: (row) => formatDateDisplay(row.date_created),
        sortable: true,
        width: '200px',
        wrap: true,
        center: true,
      },
      {
        name: 'Người tạo',
        selector: (row) => row.user_created,
        sortable: true,
        minWidth: '200px',
        center: true,
        wrap: true,
      },
      {
        name: '',
        button: true,
        selector: () => <ButtonBase>Phân quyền</ButtonBase>,
        sortable: true,
        minWidth: '100px',
        right: true,
      },
    ],
  },
  participant: {
    title: 'người dùng',
    Filter: FilterParticipant,
    Form: FormParticipant,
    columns: [
      {
        name: 'Người sử dụng',
        selector: (row) => row.email,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: 'Tên',
        selector: (row) => row.name,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: 'Nhóm',
        selector: (row) => row.group_name,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: '',
        selector: (row) => <ModalUpdatePassword email={row.email} />,
        button: true,
        sortable: true,
        width: '150px',
      },
    ],
  },
  dmnghiepvu: {
    title: 'nghiệp vụ',
    Filter: FilterNghiepVu,
    Form: FormNghiepVu,
    columns: [
      {
        name: 'Mã chứng từ',
        selector: (row) => row.ma_ct,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: 'Mã nghiệp vụ',
        selector: (row) => row.ma_nghiep_vu,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: 'Tên nghiệp vụ',
        selector: (row) => row.ten_nghiep_vu,
        sortable: true,
        minWidth: '100px',
        right: true,
      },
    ],
  },
  dmxuatxu: {
    title: 'xuất xứ',
    Form: FormXuatXu,
    Filter: FilterXuatXu,
    columns: [
      {
        name: 'Mã xuất xứ',
        selector: (row) => row.ma_xuat_xu,
        sortable: true,
        minWidth: '100px',
      },
      {
        name: 'Tên xuất xứ',
        selector: (row) => row.ten_xuat_xu,
        sortable: true,
        minWidth: '100px',
      },
    ],
  },
  pn1: {
    title: 'phiếu mua hàng',
    Form: FormPN1,
    Filter: FilterPN1,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        sortable: true,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Ngày CT',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct),
        sortable: true,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Nhà cung cấp',
        selector: (row) => row.ten_kh,
        sortable: true,
        minWidth: '140px',
        wrap: true,
        center: true,
      },
      {
        name: 'Trạng thái',
        selector: (row) => row.ten_trang_thai,
        cell: (row) => {
          return (
            <Chip
              label={row.ten_trang_thai}
              size="small"
              sx={{
                backgroundColor: row.color,
                color: '#fff',
                '& .MuiChip-label': { fontSize: '12px' },
              }}
            />
          );
        },
        sortable: true,
        minWidth: '140px',
        wrap: true,
        center: true,
      },
      {
        name: 'Kho nhập',
        selector: (row) => row.ten_kho,
        sortable: true,
        minWidth: '120px',
        wrap: true,
        center: true,
      },
      {
        name: 'Tổng tiền',
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
        sortable: true,
        minWidth: '100px',
        right: true,
      },
    ],
  },
  dmcpmh: {
    title: 'chi phí mua hàng',
    Form: FormCPMH,
    Filter: FilterCPMH,
    columns: [
      {
        name: 'Mã phí',
        selector: (row) => row.ma_cp,
      },
      {
        name: 'Tên phí',
        selector: (row) => row.ten_cp,
      },
    ],
  },
  rpt: {
    title: 'mẫu in',
    Filter: FilterRPT,
    Form: FormRPT,
    columns: [
      {
        name: 'Mã CN',
        selector: (row) => row.ma_cn,
        width: '100px',
      },
      {
        name: 'Tên mẫu in',
        selector: (row) => row.ten_mau_in,
      },
    ],
  },
};

export { dsDanhMuc };
