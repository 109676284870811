import { Grid } from '@mui/material';
import React from 'react';
import ModalBase from '../../modal/ModalBase';
import ButtonBase from '../../button/ButtonBase';
import { v4 } from 'uuid';
import { FiSave } from 'react-icons/fi';
import TextInput from '../../input/TextInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import CheckboxInput from '~/components/input/CheckboxInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';

const schema = yup.object({
  ma_kho: yup.string().required('Vui lòng nhập mã kho'),
  ten_kho: yup.string().required('Vui lòng nhập tên kho'),
  email: yup.string().email('Email không đúng định dạng'),
});

export default function FormKho({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
}) {
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          dien_thoai: defaultValues.exfields?.dien_thoai
            ? defaultValues.exfields?.dien_thoai
            : '',
        }
      : {},
    resolver: yupResolver(schema),
  });

  const handleSave = async (values) => {
    const dataPost = cloneDeep(values);
    dataPost.exfields = dataPost.exfields || {};
    dataPost.exfields.dien_thoai = values.dien_thoai || '';
    dataPost.printers = [
      {
        id_mau_in: '650abcd2e2989ec491c6d351',
      },
    ];
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmkho',
      data: dataPost,
      handleClose,
      reset,
      setLoad,
    });
  };
  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="700px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} kho`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            disabled={isEdit}
            label="Mã kho"
            placeholder="kcty"
            name="ma_kho"
            register={register}
            required
            errorMessage={errors?.ma_kho?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Tên kho"
            placeholder="kcty"
            name="ten_kho"
            register={register}
            required
            errorMessage={errors?.ten_kho?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Địa chỉ"
            placeholder="Địa điểm kho"
            name="dia_chi"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Email"
            placeholder="email"
            name="email"
            register={register}
            errorMessage={errors?.email?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Điện thoại"
            placeholder="Số điện thoại"
            name="dien_thoai"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Còn hoạt động"
                name="status"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}
