const { createSlice } = require('@reduxjs/toolkit');

const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState: {
    idApp: '',
    data: null,
  },
  reducers: {
    updateEnterprise(state, action) {
      state.data = action.payload;
    },
    updateIdApp(state, action) {
        state.idApp = action.payload
    }
  },
});

export const { updateEnterprise, updateIdApp } = enterpriseSlice.actions;
export default enterpriseSlice.reducer;
