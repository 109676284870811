import React from 'react';
import ModalBase from '../../modal/ModalBase';
import ButtonBase from '../../button/ButtonBase';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import { Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectApiInput from '~/components/input/SelectApiInput';
import CheckboxInput from '~/components/input/CheckboxInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';

const schema = yup.object({
  ten_nvt: yup.string().required('Vui lòng nhập tên nhóm'),
});

export default function FormNVT({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
}) {
  const { asyncPostData, asyncPutData } = useApisContext();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onBlur',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          nhomMe: defaultValues.nh_me
            ? {
                _id: defaultValues.nh_me,
                ten_nvt: defaultValues.ten_nh_me,
              }
            : null,
        }
      : {},
    resolver: yupResolver(schema),
  });

  const generateDataPost = (values) => {
    const { nhomMe, ...data } = values;
    const result = { ...data };
    if (nhomMe) {
      result.nh_me = nhomMe._id;
      result.ten_nh_me = nhomMe.ten_nvt;
    }
    return result;
  };

  const handleSave = async (values) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmnvt',
      data: dataPost,
      handleClose,
      reset,
      setLoad,
    });
  };
  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="700px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} nhóm vật tư`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Tên nhóm"
            placeholder="Dầu gội"
            name="ten_nvt"
            required
            register={register}
            errorMessage={errors?.ten_nvt?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="nhomMe"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                label="Nhóm mẹ"
                placeholder="Nhóm mẹ"
                apiCode="dmnvt"
                searchFileds={['ten_nvt']}
                getOptionLabel={(option) => option.ten_nvt}
                selectedValue={value}
                value={value || { _id: '', ten_nvt: '' }}
                onSelect={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="la_nhom_hang_hoa"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Là nhóm hàng hóa"
                name="la_nhom_hang_hoa"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Còn hoạt động"
                name="status"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}
