import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';
import FormKho from '../kho/FormKho';
import FormNhanVien from '../nhanvien/FormNhanVien';
import FormCustomer from '../customer/FormCustomer';
import FormKB from '../kb/FormKB';
import FormPTTT from '../pttt/FormPTTT';

function InfoTab({
  register,
  control,
  isEdit,
  errors,
  tienHang = 0,
  setValue,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextInput
          label="Số chứng từ"
          placeholder="Nhập hoặc tạo tự động"
          name="so_ct"
          register={register}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="kho"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Kho"
              required
              apiCode="dmkho"
              placeholder="Kho xuất"
              searchFileds={['ma_kho', 'ten_kho']}
              getOptionLabel={(option) => option.ten_kho}
              selectedValue={value}
              value={value || { ma_kho: '', ten_kho: '' }}
              onSelect={onChange}
              FormAdd={FormKho}
              errorMessage={errors?.kho?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          required
          type="date"
          label="Ngày chứng từ"
          placeholder="Ngày chứng từ"
          name="ngay_ct"
          register={register}
          errorMessage={errors?.ngay_ct?.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="nhan_vien"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Nhân viên"
              apiCode="dmnv"
              placeholder="Nhân viên bán hàng"
              searchFileds={['ma_nv', 'ten_nv']}
              getOptionLabel={(option) =>
                `${option.ten_nv}${option.ma_nv ? ` - ${option.ma_nv}` : ''}`
              }
              selectedValue={value}
              value={value || { ma_nv: '', ten_nv: '' }}
              onSelect={onChange}
              FormAdd={FormNhanVien}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="khach_hang"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Khách hàng"
              apiCode="customer"
              placeholder="Khách mua hàng"
              searchFileds={['ma_kh', 'ten_kh']}
              getOptionLabel={(option) =>
                `${option.ten_kh}${option.ma_kh ? ` - ${option.ma_kh}` : ''}`
              }
              selectedValue={value}
              value={value || { ma_kh: '', ten_kh: '' }}
              onSelect={onChange}
              FormAdd={FormCustomer}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="kenh_ban"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Kênh bán"
              apiCode="dmkenhbanhang"
              placeholder="Kênh bán hàng"
              searchFileds={['ma_kenh', 'ten_kenh']}
              getOptionLabel={(option) => option.ten_kenh}
              selectedValue={value}
              value={value || { ma_kenh: '', ten_kenh: '' }}
              onSelect={onChange}
              FormAdd={FormKB}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="pttt"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Phương thức thanh toán"
              apiCode="ptthanhtoan"
              placeholder="Phương thức thanh toán"
              searchFileds={['ten']}
              getOptionLabel={(option) => option.ten}
              selectedValue={value}
              value={value || { _id: '', ten: '' }}
              onSelect={onChange}
              FormAdd={FormPTTT}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="t_tien_nt"
          render={({ field: { value, onChange } }) => (
            <TextInput
              value={numeralCustom(value).format()}
              onChange={(e) => {
                const val = numeralCustom(e.target.value).value();
                onChange(val);
              }}
              label="Tiền hàng"
              placeholder="Tiền hàng bán"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="vat"
          render={({ field: { value, onChange } }) => (
            <TextInput
              value={numeralCustom(value).format()}
              onChange={(e) => {
                const val = numeralCustom(e.target.value).value();
                onChange(val);
              }}
              label="VAT (%)"
              placeholder="Thuế GTGT"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="ty_le_ck_hd"
          render={({ field: { value, onChange } }) => (
            <TextInput
              value={numeralCustom(value).format()}
              onChange={(e) => {
                // luu gia tri
                const val = numeralCustom(e.target.value).value();
                onChange(val);
                // thay doi tien ck
                const tienCkNew = (tienHang * val) / 100;
                setValue('tien_ck_hd', tienCkNew);
              }}
              label="Tỷ lệ chiết khẩu hóa đơn (%)"
              placeholder="Tỷ lệ chiết khẩu hóa đơn"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="tien_ck_hd"
          render={({ field: { value, onChange } }) => (
            <TextInput
              value={numeralCustom(value).format()}
              onChange={(e) => {
                // luu gia tri
                const val = numeralCustom(e.target.value).value();
                onChange(val);
                // thay doi ty le ck
                const tyLeCkNew = (val * 100) / tienHang;
                setValue('ty_le_ck_hd', tyLeCkNew);
              }}
              label="Tiền chiết khấu hóa đơn"
              placeholder="Tiền chiết khấu hóa đơn"
            />
          )}
        />
      </Grid>
      {isEdit && (
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="t_ck_nt"
            render={({ field: { value, onChange } }) => (
              <TextInput
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = numeralCustom(e.target.value).value();
                  onChange(val);
                }}
                label="Tổng tiền chiết khẩu"
                placeholder="Tổng tiền chiết khẩu"
              />
            )}
          />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="tien_thu2"
          render={({ field: { value, onChange } }) => (
            <TextInput
              value={numeralCustom(value).format()}
              onChange={(e) => {
                const val = numeralCustom(e.target.value).value();
                onChange(val);
              }}
              label="Tiền vận chuyển"
              placeholder="Tiền vận chuyển"
            />
          )}
        />
      </Grid>
      {isEdit && (
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="t_tt_nt"
            render={({ field: { value, onChange } }) => (
              <TextInput
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = numeralCustom(e.target.value).value();
                  onChange(val);
                }}
                label="Tổng thành tiền"
                placeholder="Tổng thành tiền"
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="tien_thu"
          render={({ field: { value, onChange } }) => (
            <TextInput
              value={numeralCustom(value).format()}
              onChange={(e) => {
                const val = numeralCustom(e.target.value).value();
                onChange(val);
              }}
              label="Tiền thu"
              placeholder="Tiền thu"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="phai_tra"
          render={({ field: { value, onChange } }) => (
            <TextInput
              value={numeralCustom(value).format()}
              onChange={(e) => {
                const val = numeralCustom(e.target.value).value();
                onChange(val);
              }}
              label="Phải trả lại"
              placeholder="Phải trả lại"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="trang_thai"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Trạng thái"
              required
              apiCode="trangthai"
              placeholder="Trạng thái phiếu"
              searchFileds={['ten_trang_thai']}
              getOptionLabel={(option) => option.ten_trang_thai}
              selectedValue={value}
              value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
              onSelect={onChange}
              withIdApp={false}
              condition={{ ma_ct: 'PBL', status: true }}
              errorMessage={errors?.trang_thai?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default InfoTab;
