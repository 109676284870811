import React, { memo } from 'react';
import { Stack } from '@mui/material';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterSelectApi from '../FilterSelectApi';
import FormUserGroup from '~/components/form/usergroup/FormUserGroup';
import { useLocation } from 'react-router-dom';

function FilterParticipant({ setCondition }) {
  const location = useLocation();
  const [filter, setFilter] = useState({
    email: '',
    name: '',
    userGroup: location.state?.userGroup || null,
  });

  useEffect(() => {
    const condition = {};
    if (filter.email) {
      condition.email = {
        $regex: filter.email,
        $options: 'i',
      };
    }
    if (filter.name) {
      condition.name = {
        $regex: filter.name.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if (filter.userGroup) {
      condition.group_id = filter.userGroup._id;
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Email"
        onSearch={(value) => setFilter({ ...filter, email: value })}
      />
      <FilterSearch
        title="Tên"
        onSearch={(value) => setFilter({ ...filter, name: value })}
      />
      <FilterSelectApi
        apiCode="usergroup"
        searchFileds={['group_name']}
        title="Nhóm người dùng"
        getOptionLabel={(option) => option.group_name}
        value={filter.userGroup || null}
        onSelect={(value) => setFilter({ ...filter, userGroup: value })}
        FormAdd={FormUserGroup}
      />
    </Stack>
  );
}

export default memo(FilterParticipant);
