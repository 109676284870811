import React, { useState, useEffect } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ButtonBase from '~/components/button/ButtonBase';
import FileInput from '~/components/input/FileInput';
import TextInput from '~/components/input/TextInput';
import ProfileLayout from '~/components/layouts/ProfileLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getProfile, updateProfile } from '~/redux/actions/auth.action';
import useToken from '~/hooks/useToken';
import useSnackbarContext from '~/hooks/hookContext/useSnackbarContext';
import useLinkImage from '~/hooks/useLinkImage';
import { cloneDeep } from 'lodash';
import useApisContext from '~/hooks/hookContext/useApisContext';

const schema = yup.object({
  name: yup.string().required('Vui lòng nhập họ và tên'),
});

function ProfilePage() {
  const profileData = useSelector((state) => state.auth.profile);
  const navigate = useNavigate();
  const token = useToken();
  const dispatch = useDispatch();
  const alertSnackbar = useSnackbarContext();
  const { uploadFile } = useApisContext();
  const generateLink = useLinkImage();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onBlur',
    defaultValues: profileData || '',
    resolver: yupResolver(schema),
  });

  const [picture, setPicture] = useState(null);

  const handleUpdate = async (values) => {
    const dataPost = cloneDeep(values);
    if (picture) {
      const formData = new FormData();
      formData.append('file', picture);
      const resp = await uploadFile({ formData, folder: 'avatars', token });
      dataPost.picture = resp?.fileUrl;
    } else if (picture === undefined) {
      dataPost.picture = '';
    }
    updateProfile({ data: dataPost, token, dispatch, alertSnackbar });
  };

  useEffect(() => {
    getProfile({ token, dispatch, alertSnackbar });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <ProfileLayout>
      <Stack alignItems="center" spacing="10px">
        <Box sx={{ width: '200px', height: '200px' }}>
          <FileInput
            url={generateLink(profileData?.picture)}
            onChange={setPicture}
          />
        </Box>
        <Grid container sx={{ width: '100%' }} spacing="10px">
          <Grid item xs={12} md={6}>
            <TextInput
              disabled
              label="Tài khoản"
              register={register}
              name="email"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Họ và tên"
              required
              register={register}
              name="name"
              errorMessage={errors?.name?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput label="Địa chỉ" register={register} name="address" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput label="Điện thoại" register={register} name="phone" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput label="Zalo" register={register} name="zalo" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput label="Whatsapp" register={register} name="whatsapp" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput label="Facebook" register={register} name="facebook" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Email không phục mật khẩu"
              register={register}
              name="email2"
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing="10px"
          sx={{ width: '100%' }}
        >
          <ButtonBase variant="outlined" onClick={() => navigate(-1)}>
            Hủy
          </ButtonBase>
          <ButtonBase
            loading={isSubmitting}
            onClick={handleSubmit(handleUpdate)}
          >
            Cập nhật
          </ButtonBase>
        </Stack>
      </Stack>
    </ProfileLayout>
  );
}

export default ProfilePage;
