import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import FormKho from '../kho/FormKho';

function InfoTab({ isEdit, register, control, errors }) {
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextInput
          label="Số chứng từ"
          placeholder="Nhập hoặc tạo tự động"
          name="so_ct"
          register={register}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          required
          label="Ngày Chứng Từ"
          type="date"
          placeholder="Ngày chứng từ"
          name="ngay_ct"
          register={register}
          errorMessage={errors?.ngay_ct?.message}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="khoXuat"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Kho xuất"
              required
              apiCode="dmkho"
              placeholder="Kho"
              searchFileds={['ma_kho', 'ten_kho']}
              getOptionLabel={(option) => option.ten_kho}
              selectedValue={value}
              value={value || { ma_kho: '', ten_kho: '' }}
              onSelect={onChange}
              FormAdd={FormKho}
              errorMessage={errors?.khoXuat?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="khoNhap"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Kho nhập"
              required
              apiCode="dmkho"
              placeholder="Kho"
              searchFileds={['ma_kho', 'ten_kho']}
              getOptionLabel={(option) => option.ten_kho}
              selectedValue={value}
              value={value || { ma_kho: '', ten_kho: '' }}
              onSelect={onChange}
              FormAdd={FormKho}
              errorMessage={errors?.khoNhap?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={control}
          name="trangThai"
          render={({ field: { onChange, value } }) => (
            <SelectApiInput
              label="Trạng thái"
              required
              apiCode="trangthai"
              placeholder="Trạng thái phiếu"
              searchFileds={['ten_trang_thai']}
              getOptionLabel={(option) => option.ten_trang_thai}
              selectedValue={value}
              value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
              onSelect={onChange}
              withIdApp={false}
              condition={{ ma_ct: 'PDN', status: true }}
              errorMessage={errors?.trangThai?.message}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default InfoTab;
