import React, { memo } from 'react';
import ButtonOption from '~/components/button/ButtonOption';
import { SlScreenDesktop } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import useResponsive from '~/hooks/useResponsive';
import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function MenuStore({ isAdmin = true }) {
  const navigate = useNavigate();
  const matches = useResponsive({ matchKey: 'up', breakpoint: 'md' });
  const theme = useTheme();

  const handleNavigate = () => {
    if (isAdmin) {
      navigate('/bill');
    } else {
      navigate('/');
    }
  };

  return (
    <Paper>
      <ButtonOption
        onClick={handleNavigate}
        style={{
          borderRadius: '4px',
          width: matches ? 'auto' : '42px',
          height: matches ? 'auto' : '42px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          backgroundImage: `linear-gradient(43deg, ${theme.palette.primary.main}, ${theme.palette.primary.second},  ${theme.palette.primary.third})`,
        }}
        endIcon={
          isAdmin ? (
            <SlScreenDesktop fontSize="14px" />
          ) : (
            <MdOutlineAdminPanelSettings fontSize="14px" />
          )
        }
      >
        {!!matches ? <>{isAdmin ? 'Bán hàng' : 'Quản trị'}</> : null}
      </ButtonOption>
    </Paper>
  );
}

export default memo(MenuStore);
