import List from '~/pages/list/List';
import HomePage from '../pages/home/HomePage';
import ChangePasswordPage from '../pages/changePassword/ChangePasswordPage';
import LoginPage from '../pages/Login/LoginPage';
import VerifyEmailPage from '../pages/register/VerifyEmailPage';
import PrivateRoute from './PrivateRoute';
import RestrictedRoute from './RestrictedRoute';
import ReportPage from '~/pages/report/ReportPage';
import Bill from '~/pages/bill/Bill';
import ProfilePage from '~/pages/profile/ProfilePage';
import TokenPage from '~/pages/devices/Divices';
import EnterprisePage from '~/pages/enterprise/EnterprisePage';
import IdAppRoute from './IdAppRoute';

const restrictedRoutes = [
  {
    id: 'login',
    path: '/login',
    page: (
      <RestrictedRoute>
        <LoginPage />
      </RestrictedRoute>
    ),
  },
  {
    id: 'verify-email',
    path: '/verify-email',
    page: (
      <RestrictedRoute>
        <VerifyEmailPage />
      </RestrictedRoute>
    ),
  },
  {
    id: 'verify-code',
    path: '/verify-code',
    page: (
      <RestrictedRoute>
        <VerifyEmailPage isForgotPassword />
      </RestrictedRoute>
    ),
  },
  {
    id: 'change-password',
    path: '/change-password',
    page: (
      <RestrictedRoute>
        <ChangePasswordPage />
      </RestrictedRoute>
    ),
  },
];
const privateRoutes = [
  {
    id: 'homepage',
    path: '/',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <HomePage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'list',
    path: '/list/:apicode',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <List />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'bill',
    path: '/bill',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <Bill />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'report',
    path: '/report/:report',
    page: (
      <PrivateRoute>
        <IdAppRoute>
          <ReportPage />
        </IdAppRoute>
      </PrivateRoute>
    ),
  },
  {
    id: 'profile',
    path: '/profile',
    page: (
      <PrivateRoute>
        <ProfilePage />
      </PrivateRoute>
    ),
  },
  {
    id: 'devices',
    path: '/devices',
    page: (
      <PrivateRoute>
        <TokenPage />
      </PrivateRoute>
    ),
  },
  {
    id: 'password',
    path: '/password',
    page: (
      <PrivateRoute>
        <ChangePasswordPage />
      </PrivateRoute>
    ),
  },
  {
    id: 'app',
    path: '/app',
    page: (
      <PrivateRoute>
        <EnterprisePage />
      </PrivateRoute>
    ),
  },
];

export { restrictedRoutes, privateRoutes };
