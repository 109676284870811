import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { SiMicrosoftexcel } from 'react-icons/si';
import ButtonBase from '~/components/button/ButtonBase';
import { TbTableExport } from 'react-icons/tb';
import TableBase from '~/components/table/TableBase';
import useApisContext from '~/hooks/hookContext/useApisContext';
import ModalImportExcel from '../modal/ModalImportExcel';
import useResponsive from '~/hooks/useResponsive';
import { BiFilterAlt, BiPlusCircle } from 'react-icons/bi';
import ButtonOption from '../button/ButtonOption';
import MenuBase from '../menu/MenuBase';
import { useMemo } from 'react';
import { BsTrash } from 'react-icons/bs';
import { useRef } from 'react';
import { FaTrash } from 'react-icons/fa';
import ModalExportExcel from '../modal/ModalExportExcel';

function ListBase({
  title,
  apiCode,
  uniqueKey,
  columns,
  Form,
  Filter,
  isDeleted = false,
  isOpenDm = false,
  fixedHeaderScrollHeight,
  filterHeight,
}) {
  const mdMatches = useResponsive({ matchKey: 'up', breakpoint: 'md' });
  const { asyncGetList } = useApisContext();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [defaultValues, setDefaultValues] = useState();
  const [condition, setCondition] = useState({});
  const [filter, setFilter] = useState({});
  const [openExcel, setOpenExcel] = useState(false);
  const [opentExportExcel, setOpenExportExcel] = useState(false);
  const [anchorMenuFiter, setAnchorMenuFilter] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationOption, setPaginationOption] = useState({
    limit: 20,
    page: 1,
    totalRows: 0,
  });
  const btnDeleteRef = useRef();

  // row per page change
  const handleRowPerPageChange = (value) => {
    setPaginationOption({ ...paginationOption, limit: value });
  };
  // handle row clicked
  const handleRowClicked = (data) => {
    setDefaultValues(data);
    setOpenForm(true);
    setIsEdit(true);
  };
  // handle close form
  const handleCloseForm = () => {
    setOpenForm(false);
    setDefaultValues(null);
  };
  // get products
  const getListData = async () => {
    setLoading(true);
    const resp = await asyncGetList({
      apiCode,
      condition: {
        limit: paginationOption.limit,
        page: paginationOption.page,
        q: condition,
      },
    });
    const respCount = await asyncGetList({
      apiCode,
      condition: {
        count: 1,
        q: condition,
      },
    });
    if (respCount) {
      setPaginationOption({
        ...paginationOption,
        totalRows: respCount.rows_number,
      });
    }
    if (resp) {
      setData(resp);
    }
    setLoading(false);
  };

  const exportToExcel = async () => {
    setOpenExportExcel(true);
  };
  // render delete button
  const renderDeleteButton = () => {
    return (
      <Tooltip placement="top" title="Xóa các dòng đã chọn" arrow>
        <IconButton
          sx={{
            backgroundColor: 'error.main',
            color: 'whitish.pureWhite',
            borderRadius: '4px',
            '&:hover': { backgroundColor: 'error.main' },
          }}
          onClick={handleDelete}
        >
          <FaTrash fontSize="14px" />
        </IconButton>
      </Tooltip>
    );
  };

  // handle delete
  const handleDelete = () => {
    btnDeleteRef?.current?.click();
  };

  // render button them moi
  const renderButtonAddNew = () => {
    return (
      <ButtonBase
        startIcon={<AiOutlinePlusCircle fontSize="14px" />}
        onClick={() => {
          setOpenForm(true);
          setIsEdit(false);
        }}
      >
        Thêm
      </ButtonBase>
    );
  };
  const renderButtonImportExcel = () => {
    return (
      <ButtonBase
        startIcon={<SiMicrosoftexcel fontSize="14px" />}
        onClick={() => setOpenExcel(true)}
      >
        Nhập excel
      </ButtonBase>
    );
  };
  const renderButtonExportExcel = () => {
    return (
      <>
        {data?.length > 0 && (
          <ButtonBase
            startIcon={<TbTableExport fontSize="14px" />}
            onClick={exportToExcel}
          >
            Xuất excel
          </ButtonBase>
        )}
      </>
    );
  };

  const showDot = useMemo(() => {
    const values = Object.values(filter);
    const isValue = values.some((item) => !!item !== false);
    return isValue;
  }, [filter]);

  useEffect(() => {
    if (apiCode) {
      getListData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOption.limit, paginationOption.page, condition, load]);
  useEffect(() => {
    if (apiCode) {
      setPaginationOption({ limit: 20, page: 1, totalRows: 0 });
      setLoad(load + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCode]);

  return (
    <>
      {openForm && (
        <Form
          open={openForm}
          defaultValues={defaultValues}
          isEdit={isEdit}
          handleClose={handleCloseForm}
          setLoad={setLoad}
        />
      )}
      {openExcel && (
        <ModalImportExcel
          open={openExcel}
          handleClose={() => setOpenExcel(false)}
          apiCode={apiCode}
          setLoad={setLoad}
        />
      )}
      {opentExportExcel && (
        <ModalExportExcel
          open={opentExportExcel}
          handleClose={() => setOpenExportExcel(false)}
          apiCode={apiCode}
        />
      )}
      {!mdMatches && (
        <MenuBase
          keepMounted
          anchorEl={anchorMenuFiter}
          open={!!anchorMenuFiter}
          handleClose={() => setAnchorMenuFilter(null)}
        >
          <Box
            className="custome-scrolly"
            sx={{
              width: '240px',
              minHeight: '100px',
              maxHeight: '300px',
              overflow: 'auto',
            }}
          >
            {Filter && (
              <Filter setCondition={setCondition} onFilterChange={setFilter} />
            )}
          </Box>
        </MenuBase>
      )}
      <Box sx={{ padding: '10px 0' }}>
        <Grid container spacing="10px" alignItems="flex-start">
          {mdMatches ? (
            <Grid item xs={0} md={2.5} xl={2}>
              <Box
                className="custome-scrolly"
                sx={{
                  width: '100%',
                  height: filterHeight || 'calc(100vh - 50px - 42px - 20px)',
                  overflow: 'auto',
                  padding: '1px',
                }}
              >
                {Filter && <Filter setCondition={setCondition} />}
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12} md={9.5} xl={10}>
            <Stack direction="row" justifyContent="space-between">
              <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                {`${isDeleted ? 'Khôi phục' : 'Danh sách'} ${title}`}
              </Typography>
              {mdMatches ? (
                <Stack direction="row" spacing="5px">
                  {!isDeleted && (
                    <>
                      {selectedRows.length > 0 && renderDeleteButton()}
                      {renderButtonAddNew()}
                      {renderButtonImportExcel()}
                      {renderButtonExportExcel()}
                    </>
                  )}
                </Stack>
              ) : null}
            </Stack>
            {!mdMatches && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing="5px"
                sx={{
                  height: '52px',
                  padding: '5px 0px',
                }}
              >
                <Stack direction="row" alignItems="center" spacing="5px">
                  <Badge variant={showDot ? 'dot' : ''} color="secondary">
                    <IconButton
                      onClick={(e) => setAnchorMenuFilter(e.currentTarget)}
                      sx={{
                        position: 'relative',
                        backgroundColor: 'primary.main',
                        width: '36px',
                        height: '36px',
                        borderRadius: '4px',
                        color: 'whitish.pureWhite',
                        '&:hover': { backgroundColor: 'primary.main' },
                      }}
                    >
                      <BiFilterAlt size={16} />
                    </IconButton>
                  </Badge>
                  {selectedRows.length > 0 && (
                    <IconButton
                      onClick={handleDelete}
                      sx={{
                        position: 'relative',
                        backgroundColor: 'error.main',
                        width: '36px',
                        height: '36px',
                        borderRadius: '4px',
                        color: 'whitish.pureWhite',
                        '&:hover': { backgroundColor: 'error.main' },
                      }}
                    >
                      <BsTrash size={16} />
                    </IconButton>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center">
                  <ButtonOption
                    startIcon={<BiPlusCircle size={14} />}
                    style={{ borderRadius: '4px' }}
                    onClick={() => {
                      setOpenForm(true);
                      setIsEdit(false);
                    }}
                  >
                    Mới
                  </ButtonOption>
                  <Divider orientation="vertical" sx={{ height: '14px' }} />

                  <Divider orientation="vertical" sx={{ height: '14px' }} />
                  <ButtonOption
                    startIcon={<SiMicrosoftexcel size={14} />}
                    style={{ borderRadius: '4px' }}
                    onClick={() => setOpenExcel(true)}
                  >
                    Nhập
                  </ButtonOption>
                  <Divider orientation="vertical" sx={{ height: '14px' }} />
                  <ButtonOption
                    startIcon={<TbTableExport size={14} />}
                    style={{ borderRadius: '4px' }}
                    onClick={exportToExcel}
                  >
                    Xuất
                  </ButtonOption>
                </Stack>
              </Stack>
            )}
            <Box sx={{ paddingTop: '10px' }}>
              <TableBase
                apiCode={apiCode}
                uniquekey={uniqueKey}
                columns={columns || []}
                data={data}
                title={title}
                progressPending={loading}
                paginationTotalRows={paginationOption.totalRows}
                paginationPerPage={paginationOption.limit}
                onChangeRowsPerPage={handleRowPerPageChange}
                onChangePage={(value) =>
                  setPaginationOption({ ...paginationOption, page: value })
                }
                onSelectedRowsChange={setSelectedRows}
                onRowClicked={handleRowClicked}
                loadData={getListData}
                isDeleted={isDeleted}
                fixedHeaderScrollHeight={
                  fixedHeaderScrollHeight ||
                  `calc(100vh - 50px - ${
                    !!mdMatches ? '42px' : '52px'
                  } - 34px - 10px - 34px - 20px - 18px)`
                }
                isOpenDm={isOpenDm}
                ref={btnDeleteRef}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ListBase;
