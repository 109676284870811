import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterTimeFromTo from '../FilterTimeFromTo';
import FilterSelectApi from '../FilterSelectApi';
import moment from 'moment';

function FilterPNK({ setCondition }) {
  const [filter, setFilter] = useState({
    soCt: '',
    vatTu: null,
    kho: null,
    nhaCungCap: null,
    trangThai: null,
    timeFrom: '',
    timeTo: moment().format('YYYY-MM-DD'),
  });

  useEffect(() => {
    const condition = {};
    if (filter.soCt) {
      condition.so_ct = filter.soCt;
    }
    if (filter.vatTu) {
      condition.details = {
        $elemMatch: {
          ma_vt: filter.vatTu.ma_vt,
        },
      };
    }
    if (filter.nhaCungCap) {
      condition.ma_kh = filter.nhaCungCap.ma_kh;
    }
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    if (filter.trangThai) {
      condition.trang_thai = filter.trangThai.ma_trang_thai;
    }
    if (filter.timeFrom || filter.timeTo) {
      const startDate = moment(filter.timeFrom).toDate();
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      const endDate = moment(filter.timeTo).toDate();
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      if (filter.timeFrom && filter.timeTo) {
        condition.ngay_ct = { $gte: startDate, $lte: endDate };
      } else if (filter.timeFrom) {
        condition.ngay_ct = { $gte: startDate };
      } else if (filter.timeTo) {
        condition.ngay_ct = { $lte: endDate };
      }
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Số chứng từ"
        onSearch={(value) => setFilter({ ...filter, soCt: value })}
      />
      <FilterSelectApi
        title="Trạng thái"
        apiCode="trangthai"
        condition={{ ma_ct: 'PNK', status: true }}
        withIdApp={false}
        value={
          filter.trangThai
            ? {
                ma_trang_thai: filter.trangThai.ma_trang_thai,
                ten_trang_thai: filter.trangThai.ten_trang_thai,
              }
            : null
        }
        searchFileds={['ma_trang_thai', 'ten_trang_thai']}
        getOptionLabel={(option) => option.ten_trang_thai}
        onSelect={(value) => setFilter({ ...filter, trangThai: value })}
      />
      <FilterSelectApi
        title="Hàng hóa"
        apiCode="dmvt"
        value={
          filter.vatTu
            ? { ma_vt: filter.vatTu.ma_vt, ten_vt: filter.vatTu.ten_vt }
            : null
        }
        searchFileds={['ma_vt', 'ten_vt']}
        getOptionLabel={(option) => option.ten_vt}
        onSelect={(value) => setFilter({ ...filter, vatTu: value })}
      />
      <FilterSelectApi
        title="Nhà cung cấp"
        apiCode="ecomsellers"
        value={
          filter.nhaCungCap
            ? { ma_kh: filter.nhaCungCap.ma_kh, ten_kh: filter.nhaCungCap.ten_kh }
            : null
        }
        searchFileds={['ma_kh', 'ten_kh']}
        getOptionLabel={(option) => option.ten_kh}
        onSelect={(value) => setFilter({ ...filter, nhaCungCap: value })}
      />
      <FilterSelectApi
        title="Kho"
        apiCode="dmkho"
        value={
          filter.kho
            ? { ma_kho: filter.kho.ma_kho, ten_kho: filter.kho.ten_kho }
            : null
        }
        searchFileds={['ma_kho', 'ten_kho']}
        getOptionLabel={(option) => option.ten_kho}
        onSelect={(value) => setFilter({ ...filter, kho: value })}
      />
      <FilterTimeFromTo
        title="Ngày chứng từ"
        defaultTimeTo={filter.timeTo}
        onSearch={(time) => setFilter({ ...filter, ...time })}
      />
    </Stack>
  );
}

export default FilterPNK;
