import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterRadios from '../FilterRadios';
import moment from 'moment';
import FilterTimeFromTo from '../FilterTimeFromTo';
import FilterSelectApi from '../FilterSelectApi';
import { groupBy, numeralCustom } from '~/utils/helpers';

const concerns = [
  {
    value: 'banhang',
    label: 'Bán hàng',
    api: 'ctbanle',
    convertData: (data) => {
      const result = [];
      data.pop();
      const dataGroup = groupBy({ data, callbackMatch: (item) => item.ma_vt });

      dataGroup.forEach((group) => {
        const res = group.reduce(
          (acc, item) => {
            return {
              ma_vt: item.ma_vt,
              ten_vt: item.ten_vt,
              sl_ban: acc.sl_ban + 1,
              doanh_thu: acc.doanh_thu + item.tien_nt,
            };
          },
          { ma_vt: '', ten_vt: '', sl_ban: 0, doanh_thu: 0 }
        );
        result.push(res);
      });

      return result;
    },
    columns: [
      {
        name: 'Mã hàng',
        selector: (row) => row.ma_vt,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Tên hàng',
        selector: (row) => row.ten_vt,
        center: true,
      },
      {
        name: 'Số lượng bán',
        selector: (row) => row.sl_ban,
        center: true,
      },
      {
        name: 'Doanh thu',
        selector: (row) => row.doanh_thu,
        format: (row) => numeralCustom(row.doanh_thu).format(),
        right: true,
      },
    ],
  },
  {
    value: 'thnxt',
    label: 'Nhập xuất tồn',
    labelKey: 'ten_kho',
    api: 'thnxt',
    convertData: (data) => {
      return data || [];
    },
    columns: [
      {
        name: 'Mã hàng hóa',
        selector: (row) => row.ma_vt,
        minWidth: '130px',
      },
      {
        name: 'Tên hàng hóa',
        selector: (row) => row.ten_vt,
        minWidth: '130px',
        wrap: true,
      },
      {
        name: 'Tồn đầu kỳ',
        selector: (row) => row.ton_dau,
        minWidth: '130px',
        center: true,
      },
      {
        name: 'Nhập kho',
        selector: (row) => row.sl_nhap,
        minWidth: '130px',
        center: true,
      },
      {
        name: 'Xuất kho',
        selector: (row) => row.sl_xuat,
        width: '130px',
        center: true,
      },
      {
        name: 'Tồn cuối kỳ',
        selector: (row) => row.ton_cuoi,
        minWidth: '130px',
        right: true,
      },
    ],
  },
];

function FilterBaoCaoHangHoa({
  setQueryObject = () => {},
  setConcern = () => {},
}) {
  const [valueConcern, setValueConcern] = useState(concerns[0].value);
  const [filter, setFilter] = useState({
    timeFrom: moment().startOf('months').format('YYYY-MM-DD'),
    timeTo: moment().format('YYYY-MM-DD'),
    kho: null,
    vat_tu: null,
  });

  useEffect(() => {
    setConcern(concerns.find((item) => item.value === valueConcern));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueConcern]);
  useEffect(() => {
    const queryObject = {};
    if (filter.timeFrom) {
      queryObject.tu_ngay = filter.timeFrom;
    }
    if (filter.timeTo) {
      queryObject.den_ngay = filter.timeTo;
    }
    if (filter.kho) {
      queryObject.ma_kho = filter.kho.ma_kho;
    }
    if (filter.vat_tu) {
      queryObject.ma_vt = filter.vat_tu.ma_vt;
    }
    setQueryObject(queryObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack spacing={1}>
      <FilterRadios
        title="Mối quan tâm"
        values={concerns}
        defaultValue={valueConcern}
        onChange={(newConcern) => setValueConcern(newConcern)}
      />
      <FilterSelectApi
        title="Kho"
        apiCode="dmkho"
        value={
          filter.kho
            ? { ma_kho: filter.kho.ma_kho, ten_kho: filter.kho.ten_kho }
            : null
        }
        searchFileds={['ma_kho', 'ten_kho']}
        getOptionLabel={(option) => option.ten_kho}
        onSelect={(value) => setFilter({ ...filter, kho: value })}
      />
      <FilterSelectApi
        title="Hàng hóa"
        apiCode="dmvt"
        value={
          filter.vat_tu
            ? { ma_vt: filter.vat_tu.ma_vt, ten_vt: filter.vat_tu.ten_vt }
            : null
        }
        searchFileds={['ma_vt', 'ten_vt']}
        getOptionLabel={(option) => option.ten_vt}
        onSelect={(value) => setFilter({ ...filter, vat_tu: value })}
      />
      <FilterTimeFromTo
        defaultTimeFrom={filter.timeFrom}
        defaultTimeTo={filter.timeTo}
        title="Thời gian"
        onSearch={(time) => setFilter({ ...filter, ...time })}
      />
    </Stack>
  );
}

export default FilterBaoCaoHangHoa;
