import React from 'react';
import AdminLayout from './AdminLayout';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ButtonOption from '../button/ButtonOption';
import { useLocation, useNavigate } from 'react-router-dom';
import useResponsive from '~/hooks/useResponsive';

const routes = [
  {
    text: 'Thông tin chung',
    path: '/profile',
  },
  {
    text: 'Đổi mật khẩu',
    path: '/password',
  },
  {
    text: 'Thiết bị khác',
    path: '/devices',
  },
];

function ProfileLayout({ children }) {
  const mdMatches = useResponsive({ key: 'up', breakpoint: 'md' });
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const renderTitle = () => (
    <Typography
      component="h1"
      sx={{ fontSize: '20px', fontWeight: 500, paddingBottom: '10px' }}
    >
      Tài khoản của tôi
    </Typography>
  );

  return (
    <AdminLayout>
      <Box sx={{ height: '100%', padding: '10px 0' }}>
        {!mdMatches && renderTitle()}
        <Grid container spacing="10px" alignContent="flex-start">
          <Grid item xs={12} md={3}>
            <Stack direction={mdMatches ? 'column' : 'row'} spacing="5px">
              {routes.map((route) => {
                const isActive = location.pathname.includes(route.path);
                return (
                  <ButtonOption
                    key={route.text}
                    active={isActive}
                    style={
                      isActive
                        ? mdMatches
                          ? {
                              border: 'none',
                              borderLeft: '2px solid',
                              borderColor: theme.palette.primary.main,
                              borderRadius: '0 4px 4px 0',
                            }
                          : {
                              border: 'none',
                              flexGrow: 1,
                              borderBottom: '2px solid',
                              borderColor: theme.palette.primary.main,
                              borderRadius: '4px 4px 0 0',
                            }
                        : mdMatches
                        ? null
                        : { flexGrow: 1 }
                    }
                    onClick={() => navigate(route.path)}
                  >
                    {route.text}
                  </ButtonOption>
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box
              className="hidden-scroll"
              sx={{
                width: '100%',
                maxHeight: mdMatches
                  ? `calc(100vh - 50px - 42px - 20px)`
                  : 'calc(100vh - 50px - 40px - 50px - 20px)',
                overflow: 'auto',
              }}
            >
              {mdMatches && renderTitle()}
              <Box>{children}</Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </AdminLayout>
  );
}

export default ProfileLayout;
