import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterSelectApi from '../FilterSelectApi';
import FilterTimeFromTo from '../FilterTimeFromTo';
import moment from 'moment';

function FilterPC({ setCondition }) {
  const [filter, setFilter] = useState({
    so_ct: '',
    kho: null,
    nguoiNhan: null,
    trangThai: null,
    timeFrom: '',
    timeTo: moment().format('YYYY-MM-DD'),
  });

  useEffect(() => {
    const condition = {};
    if (filter.so_ct) {
      condition.so_ct = filter.so_ct;
    }
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    if (filter.nguoiNhan) {
      condition.ma_kh = filter.nguoiNhan.ma_kh;
    }
    if (filter.trangThai) {
      condition.trang_thai = filter.trangThai.ma_trang_thai;
    }
    if (filter.timeFrom || filter.timeTo) {
      const startDate = moment(filter.timeFrom).toDate();
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      const endDate = moment(filter.timeTo).toDate();
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      if (filter.timeFrom && filter.timeTo) {
        condition.ngay_ct = { $gte: startDate, $lte: endDate };
      } else if (filter.timeFrom) {
        condition.ngay_ct = { $gte: startDate };
      } else if (filter.timeTo) {
        condition.ngay_ct = { $lte: endDate };
      }
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Số chứng từ"
        onSearch={(value) => setFilter({ ...filter, so_ct: value })}
      />
      <FilterSelectApi
        title="Trạng thái"
        apiCode="trangthai"
        condition={{ ma_ct: 'PT1', status: true }}
        withIdApp={false}
        value={
          filter.trangThai
            ? {
                ma_trang_thai: filter.trangThai.ma_trang_thai,
                ten_trang_thai: filter.trangThai.ten_trang_thai,
              }
            : null
        }
        searchFileds={['ma_trang_thai', 'ten_trang_thai']}
        getOptionLabel={(option) => option.ten_trang_thai}
        onSelect={(value) => setFilter({ ...filter, trangThai: value })}
      />
      <FilterSelectApi
        title="Kho"
        apiCode="dmkho"
        value={
          filter.kho
            ? { ma_kho: filter.kho.ma_kho, ten_kho: filter.kho.ten_kho }
            : null
        }
        searchFileds={['ma_kho', 'ten_kho']}
        getOptionLabel={(option) => option.ten_kho}
        onSelect={(value) => setFilter({ ...filter, kho: value })}
      />
      <FilterSelectApi
        title="Người nhận"
        apiCode="ecomsellers"
        value={
          filter.nguoiNhan
            ? {
                ma_kh: filter.nguoiNhan.ma_kh,
                ten_kh: filter.nguoiNhan.ten_kh,
              }
            : null
        }
        searchFileds={['ma_kh', 'ten_kh']}
        getOptionLabel={(option) => `${option.ten_kh} - ${option.ma_kh}`}
        onSelect={(value) => setFilter({ ...filter, nguoiNhan: value })}
      />
      <FilterTimeFromTo
        title="Ngày lập phiếu"
        defaultTimeTo={filter.timeTo}
        onSearch={(time) => setFilter({ ...filter, ...time })}
      />
    </Stack>
  );
}

export default FilterPC;
